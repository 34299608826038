.alt-main-menu {
	padding-bottom: rem-calc(79);
	padding-top: rem-calc(22);
	li {
		font-size: rem-calc(17);
		line-height: rem-calc(26);
	}
	li.first {
		a {
			padding-left: rem-calc(10);
		}
	}
	a {
		text-transform: uppercase;
		font-weight: 600;
	}
  li.last {
    padding: rem-calc(10) rem-calc(12);
  }
  .social-btn {
    //@include button();
    background-color: $silver;
    outline: none;
    padding: 0;
    margin-bottom: 0;
    color: $silver;
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem-calc(20);
    line-height: rem-calc(20);
    text-indent: 9999px;
    height: rem-calc(22);
    &:hover {
      background-color: $silver;
      color: $silver;
    }
  }
  #instagram-btn {
    background: url('../images/layout/images/sb_ig_icon.png') no-repeat 0 0 $white;
    padding-right: 0;
    width: rem-calc(20);
  }
}
.logo-wrap {
	margin-top: rem-calc(310);
	display: block;
	@include grid-column(3);
	@include grid-column-offset(9);
	.logo {
	padding-left: 0;
	margin-bottom: -50px;
	}
}
.tabs {
	background: transparent;
	border: 0px solid $white;
}
@include breakpoint(medium only) {
	.title-area {
		position: relative;
		z-index: 1000;
	}
	.logo-wrap {
		.logo {
		@include grid-column(6);
		}
	}
}
