#branding {
	//background: $white;
}
.container {
	width: 100%;
	margin-bottom: 0;
	margin-top: rem-calc(38);
	padding-top: rem-calc(97);
	padding-bottom: rem-calc(49);
}
.row.full-width {
	max-width: 100%;
}
#page-title {
	text-transform: uppercase;
	color: $silver;
	font-size: rem-calc(40);
	line-height: rem-calc(46);
	font-weight: 700;
}
.breadcrumbs {
	font-weight: normal;
	margin-bottom: rem-calc(80);
	.first {
		a,a:hover {
			text-indent: -9999px;
			background: url("../images/layout/images/home-icon.png") no-repeat scroll 0 -2px transparent;
			display: block;
			width: 18px;
			height: 17px;
		}
	}
}
.main {
	// box-sizing:content-box;
	@include grid-row;
	article {
		@include grid-column;
	}
	article.node-episode-track {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: rem-calc(38);
	}
}
.field-intro {
	margin-bottom: rem-calc(48);
	p {
		font-size: rem-calc(26);
		line-height: rem-calc(44);
		font-weight: 400;
		-ms-word-break: break-word;
			word-break: break-word;
		word-break: break-word;
		-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
				hyphens: auto;
	}
}
.posted {
	text-transform: uppercase;
	color: $silver;
	font-weight: 400;
	font-size: rem-calc(17);
	line-height: rem-calc(44);
}
// Individual Episodes
.node-type-episode {
	#branding {
		background: url("../images/pages/images/Home_bg-2017-BW-1440.jpg") no-repeat center top $white;
		padding-bottom: 0;
	}
	.main {
		padding-bottom: rem-calc(58);
	}
	.featured-wrap {
		@include breakpoint(medium) {
			@include grid-column(2);
			@include grid-column-position(10);
			.block-bean-itunes-badge {
				margin: rem-calc(30) auto;
			}
		}
		@include grid-column(4);
		@include grid-column-position(8);
		.block-bean-itunes-badge {
			margin: rem-calc(174) auto rem-calc(30);
		}
	}
	.podcast-row {
		background-color: $red-link;
		padding-top: rem-calc(97);
		padding-bottom: rem-calc(77);
		margin-bottom: 0;
		.panel {
			background-color: #d5c3bb;
			border: solid 1px #d5c3bb;
		}
	}
	h3.episodes-cromb {
		text-transform: uppercase;
		color: $silver;
		font-size: rem-calc(17);
		line-height: rem-calc(44);
		font-weight: 600;
		margin-bottom: 0;
	}
	.field-headline {
		p {
			text-transform: uppercase;
			color: $silver;
			font-size: rem-calc(26);
			line-height: rem-calc(44);
			font-weight: 500;
			margin-bottom: rem-calc(36);
		}
	}
	.block-bean-itunes-badge {
		padding-left: rem-calc(10);
		clear: both;
	}
	#row-callout-wrap {
		background-color: $iron;
		margin-top: 0;
		.field-intro {
			.section-head {
				color: $white;
				font-size: rem-calc(24);
				line-height: rem-calc(42);
				font-weight: 400;
				text-transform: uppercase;
			}
			p {
				color: $white;
				font-weight: 400;
				font-size: rem-calc(26);
				line-height: rem-calc(44);
			}
			a, a:hover {
				color: $white;
				font-weight: 400;
				font-size: rem-calc(26);
				line-height: rem-calc(44);
			}
			.episode-cta {
				p {
					margin-bottom: 0;
					a, a:hover {
						color: $red-link;
						font-size: rem-calc(24);
						line-height: rem-calc(38);
						font-weight: 300;
						border-bottom: solid 2px $red-link;
					}
				}
				p:last-child {
					margin-bottom: rem-calc(40);
				}
			}
		}
	}
}
// Episodes
.episodes-tabs {
	@include tabs-container;
	li {
		@include tabs-title;
		a {
			text-transform: uppercase;
			text-align: left;
			padding-left: 0;
      font-weight: 600;
		}
	}
}
.tabs-content {
	@include tabs-content;
	padding-bottom: rem-calc(98);
	.tabs-panel {
		@include tabs-panel;
		.episode-link {
			a {
				font-size: rem-calc(24);
				line-height: rem-calc(54);
				text-transform: uppercase;
			}
		}
	}
}
// Core Values
.section-core-values [class*="block-grid-"] > li {
	padding-bottom: 0;
}
.section-core-values,
.section-quotes {
	.container {
		padding-bottom: rem-calc(82);
	}
	.cv-row {
		@include grid-column(6);
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
		padding-bottom: rem-calc(15);
	}
	.views-row-3 {
		@include grid-column(12);
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
	}
	.views-field-nothing {
		position:relative;
		// display:none;
		visibility: hidden;
		margin: 0 auto;
	}
	.views-field-nothing.show {
		visibility: visible;
	}
}
.row .row.cv-row {
	margin-left: 0;
	margin-right: 0;
}
.a2a_kit {
	display: block;
	width: 105px;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	position: absolute;
	top: 30px;
	left: 50%;
	margin-left: -53px;
	// padding-right: 15px;
}
.a2a_default_style a.a2a_button_facebook,
.a2a_default_style a.a2a_button_twitter,
.a2a_default_style a.a2a_button_google_plus {
	padding-left: 6px;
	padding-right: 6px;
}
.a2a_default_style a.a2a_button_twitter {
	padding-left: 8px;
}
.a2a_default_style a.a2a_button_google_plus {
	padding-right: 0;
}
.cv-wrapper {
	min-width: 50%;
}
// Quotes
.section-quotes {
	.views-row-3 {
		@include grid-column(6);
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
	}
	.large-block-grid-2 > li:nth-of-type(2n+1) {
		padding-left: rem-calc(9);
		padding-right: rem-calc(9);
	}
	.cv-row {
		@include grid-column(6);
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
		padding-bottom: rem-calc(18);
	}
}
// Fortytude
.section-fortytude {
  .field-intro {
    margin-bottom: 0;
  }
  a.button {
    text-transform: uppercase;
    font-weight: 600;
  }
  #page-title {

  }
}
.section-fortytude {

	.container {
		padding-bottom: rem-calc(77);
	}
	.main {
		padding-bottom: rem-calc(58);
	}
	#two-up {
		background-color: $btn-smoke;
		margin-top: 0;
		color: $silver;
		padding-bottom: rem-calc(32);
		.first {
			padding-right: rem-calc(12)
		}
		.last {
			padding-left: rem-calc(12);
		}
		p {
			margin-bottom: 0;
			font-size: rem-calc(24);
			line-height: rem-calc(32);
			text-transform: uppercase;
			font-weight: normal;
			a, a:hover {
				color: $silver;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				font-weight: normal;
				border-bottom: solid 2px $red-link;
			}
		}
	}
}
#node-18 {
	.body {
		p {
			a, a:hover {
				text-decoration: underline;
			}
		}
	}
}
// Professional Services
.page-node-14 {
  #page-title {

  }
  #row-callout-wrap {
    background-color: $btn-smoke;
    padding-bottom: rem-calc(97);
    .sb-panel {
      background-color: $btn-smoke;
      border-width: 0;
      p, span, ul {
        color: $silver;
        font-size: rem-calc(24);
        line-height: rem-calc(42);
        font-weight: 400;
        margin-bottom: rem-calc(20);
      }
      ul {
        line-height: rem-calc(42);
        li {
			border-color: $btn-smoke;
			padding-left: 0;
          a {
			  border-color: $btn-smoke;
			  padding-top: 0;
			  padding-bottom: 0;

          }
        }
      }
    }
  }
	.main {
		padding-bottom: rem-calc(58);
	}
	.container {
		margin-top: 0;
	}
	.qa-header {
		color: $silver;
		font-weight: 700;
		font-size: rem-calc(32);
		line-height: rem-calc(36);
		text-transform: uppercase;
		margin-bottom: rem-calc(44);
	}
	.accordion .accordion-item > a {
		font-size: rem-calc(24);
		line-height: rem-calc(42);
    font-weight: 600;
		color: $silver;
		background: $btn-smoke;
		padding-bottom: rem-calc(4);
		padding-left: 0;
		padding-right: 0;
		padding-top: rem-calc(4);
		border: 0px solid $btn-smoke;
	}
	.accordion .accordion-item {
		padding-left: 0;
		.accordion-content {
			padding: rem-calc(10);
			background-color: $btn-smoke;
		}
	}
	.qa-answer.is-active {
		color: $silver;
		padding-top: rem-calc(8);
	}
}
#branding {
	background: url("../images/pages/images/Home_bg-2017-BW-1440.jpg") no-repeat center top $white;
	background-attachment: fixed;
	position: relative;
	bottom: 0;
	left: 0;
	z-index: 2;
	margin-top: rem-calc(0);
	margin-bottom: rem-calc(0);
	padding-bottom: rem-calc(0);
	color: $white;
}
#branding.container {
	margin-top: rem-calc(0);
	padding-top: rem-calc(46);
}
// Home page
.front {
	.l-header {
		 position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
	}
	.featured-wrap {
		.block-bean-itunes-badge {
			@include grid-column(2);
			.content {
				.ibadge {
					float: right;
				}
			}
		}
	}
	.l-main {
		margin-top: rem-calc(0);
		padding-top: rem-calc(0);
		background-position: center bottom;
	}
	.field-intro {
		margin-bottom: rem-calc(98);
		p {
			font-size: rem-calc(26);
			line-height: rem-calc(44);
			font-weight: 400;
			text-align: center;
			margin-bottom: 0;
		}
		.signature p {
			line-height: rem-calc(26);
		}
		a, a:hover {
			font-weight: 400;
			color: $red-link;
			border-bottom: $white 0px solid;
			margin-bottom: rem-calc(82);
		}
    a.button, a.button:hover {
      font-weight: 600;
    }
	}
	#row-callout-wrap {
		background-color: $red-link;
		margin-top: 0;
		color: $white;
		// padding-top: rem-calc(90);
		padding-bottom: rem-calc(97);
		.field-intro {
			margin-bottom: 0;
			p {
				text-transform: uppercase;
				line-height: rem-calc(44);
				font-size: rem-calc(26);
				font-weight: 400;
				margin-bottom: 0;
				color: rgba($white, .7);
				a, a:hover {
					font-size: rem-calc(16);
					font-weight: 400;
					color: $white;
					border-bottom: $white 2px solid;
					margin-bottom: rem-calc(82);
				}
			}
		}
	}
	#two-up {
		background: $white;//url("../images/layout/images/sarah-bg.png") repeat scroll 0 0 $oil;
		margin-top: 0;
		padding-top: 0;
		position: relative;
		bottom: 44px;
		color: $silver;
		padding-bottom: rem-calc(43);
		padding-top: 0;
		.first {
			// padding-top: 0;
			// background-color: $iron;
			text-align: center;
			.block-bean-hp-episods-callout {
				background-color: $iron;
				min-height: 450px;
				// height: 43px;
				img {
					margin-top: rem-calc(33);
					padding-bottom: rem-calc(43);
				}
				.field-intro {
					max-width: rem-calc(370);
					margin: auto;
					padding-bottom: rem-calc(33);
				}
			}
		}
		.last {
			// padding-top: 0;
			text-align: center;
			.block-bean-hp-book-callout {
				background-color: $btn-smoke;
				min-height: 450px;
				img {
					margin-top: rem-calc(33);
					padding-bottom: rem-calc(30);
				}
				.field-intro {
					max-width: rem-calc(362);
					margin: auto;
					padding-bottom: rem-calc(33);
				}
			}
			span.em-dash:before {
				content:"\2014";
				display: inline;
			}
		}
		p {
			margin-bottom: 0;
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			text-transform: uppercase;
			font-weight: 400;
			-ms-word-break: break-word;
				word-break: break-word;
			word-break: break-word;
			-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
					hyphens: auto;
			a, a:hover {
				color: $silver;
				margin-top: rem-calc(16);
				display: inline-block;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				font-weight: 400;
				border-bottom: solid 2px $red-link;
			}
		}
	}
	#hp-row-callout-wrap {
		background: #e17860;
		margin-top: 0;
		padding-top: rem-calc(0);
		padding-bottom: rem-calc(0);
		 max-height: 100%;
		.row-callout {
			padding-left: 0;
			padding-right: 0;
		}
		.field-intro {
			margin-bottom: 0;
		}
		.block-bean-home-page-bio {
			@include grid-column(7);
			padding-left: 0;
			float: none;
			.hp-bio-wrapper {
				p {
					color: $white;
					font-size: rem-calc(24);
					line-height: rem-calc(32);
					font-weight: 300;
					margin-bottom: 0;
					a, a:hover {
						color: $white;
						font-size: rem-calc(16);
						line-height: rem-calc(26);
						text-transform: uppercase;
						border-bottom: solid 2px $white;
						font-weight: 300;
					}
				}
			}
		}
	}
	#hp-book-callout-wrap {
		margin-top: 0;
		padding-top: rem-calc(97);
		padding-bottom: rem-calc(49);
		.block-bean-hp-book-callout {
			.field-cv-image {
				@include grid-column(3);
				padding-left: 0;
			}
			.field-intro {
				@include grid-column(9);
				.hp-book-callout {
					p {
						text-transform: uppercase;
						font-size: rem-calc(40);
						line-height: rem-calc(46);
						font-weight: 400;
						-ms-word-break: keep-all;
							word-break: keep-all;
						word-break: keep-all;
						-webkit-hyphens: none;
							-moz-hyphens: none;
							-ms-hyphens: none;
								hyphens: none;
						margin-bottom: 0;
						padding-right: rem-calc(28);
					}
					.signature {
						p:before { content:"\2014";}
					}
					.read-link {
						p {
							line-height: rem-calc(26);
							font-size: rem-calc(20);
							a, a:hover {
								color: $silver;
								font-size: rem-calc(16);
								line-height: rem-calc(32);
								text-transform: uppercase;
								border-bottom: solid 2px $red-link;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}
// Contact
.section-contact {
  #page-title {

  }
}
.section-contact #node-4 {
	.field-intro {
		margin-bottom: rem-calc(68);
		p {
			color: $silver;
		}
	}
}
.section-contact #row-callout-wrap {
	background: $btn-smoke;
	margin-top: 0;
	padding-top: rem-calc(75);
	padding-bottom: rem-calc(75);
	color: $white;
	.field-intro {
		p {
			a, a:hover {
				color: $silver;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				padding-top: rem-calc(20);
				font-weight: 400;
			}
		}
	}
	.block-webform {
		min-height: rem-calc(454);
		@include grid-row();
		.webform-component-markup {
			p {
				text-transform: uppercase;
				font-weight: normal;
				font-size: rem-calc(24);
				line-height: rem-calc(32);
			}
		}
		.secret-wrapper {
			@include grid-column(10);
			padding-right: 0;
			padding-left: 0;
			margin-top: 0;
			.form-textarea-wrapper {
				height: rem-calc(62);
				.contact-secret {
					max-height: rem-calc(62);
					background-color: rgba($white, 0.7);
					color: $primary-color;
					font-size: rem-calc(24);
					font-weight: normal;
				}
			}
		}
		.webform-submit {
			@include grid-column(2);
			padding-right: 0;
			padding-left: 0;
			margin-bottom: 0;
			height: rem-calc(62);
			text-transform: uppercase;
			font-weight: normal;
		}
	}
	.block-bean-contact-bottom {
		// padding-top: rem-calc(76);
		.field-intro {
			margin-bottom: 0;
			p {
				font-size: rem-calc(26);
				line-height: rem-calc(44);
				font-weight: 400;
				margin-bottom: rem-calc(8);
				color: $silver;
			}
			.contact-links p {
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				margin-bottom: 0;
			}
			a, a:hover {
				color: $silver;
				font-size: rem-calc(17);
				line-height: rem-calc(26);
				text-transform: uppercase;
				text-align: left;
				padding-left: 0;
			}
			a:hover {
				color: $red-link;
			}
		}
	}
}

.page-node-2 {
	.container {
		padding-bottom: rem-calc(97);
	}
	.body {
		p {
			text-transform: uppercase;
			line-height: rem-calc(32);
			font-size: rem-calc(24);
			font-weight: 300;
			margin-bottom: 0;
			a, a:hover {
				color: $silver;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				font-weight: 400;
				border-bottom: solid 2px $red-link;
				text-decoration: none;
			}
		}
	}
}
// Articles
.block-views-articles-block {
	.section-head {
		font-size: rem-calc(28);
		line-height: rem-calc(36);
	}
	.field-intro {
		p {
			-moz-hyphens: auto;
			font-size: rem-calc(28);
			line-height: rem-calc(36);
			font-weight: 300;
			a, a:hover {
				text-decoration: underline;
				display: inline-block;
				word-wrap: break-word;
				-ms-word-break: break-word;
					word-break: break-word;
				word-break: break-word;
				-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
						hyphens: auto;
			}
		}
	}
}
// Exercises
.page-node-5 {
	.container {
		padding-bottom: rem-calc(97);
	}
}
.view-exercises .red-link {
	a, a:hover {
		color: $red-link;
		font-size: rem-calc(28);
		line-height: rem-calc(44);
		border-bottom: solid 2px $red-link;
		display: inline-block;
		font-weight: 400;
	}
}
.page-node-10 .exercise-bot {
	margin-top: rem-calc(40);
	margin-bottom: rem-calc(40);
}
// #thenotsosecretsociety
.section-thenotsosecretsociety .container {
	padding-bottom: rem-calc(77);
}
.tnsss-grid {
	text-align: center;
}
.thumbnail {
		position:relative;
		overflow:hidden;
		padding: 0;
		border-radius: 0;
		box-sizing: border-box;
		border: none;
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		float: none;
		// margin-bottom: rem-calc(12);
}
.caption {
		position:absolute;
		box-sizing: border-box;
		top:0;
		left:0;
		background:rgba(213, 195, 187, 0.70);
		width:296px;
		height:307px;
		display: none;
		text-align: center;
		color:#fff !important;
		z-index:2;
		.caption-inner {
			display: table;
			.field-tnsss-quote {
				padding-top: 12%;
				width: 280px;
				height: 307px;
				margin: 0 auto;
				vertical-align: middle;
				display: table-cell;
				p {
					text-transform: uppercase;
					font-size: rem-calc(16);
					line-height: rem-calc(19);
					margin-bottom: rem-calc(6);
					text-align: left;
					vertical-align: middle;
					padding: 0 rem-calc(5) 0 rem-calc(19);
				}
				.signature {
					vertical-align: middle;
					display: table-row;
					p{
						font-size: rem-calc(16);
						line-height: rem-calc(19);
					}
				}
			}
		}
}
// Media
.section-media {
  #page-title {
    display: none;
  }
}
.block-views-media-gallery-block {
	@include grid-column;
	padding-left: 0;
	padding-right: 0;
	margin-top: rem-calc(0);
	margin-bottom: rem-calc(100);
}
.media-wrapper {
	text-align: center;
	padding-left: 0;
	.helper {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
	.ff-preview {
		background-color: $white;
		border: 1px solid $btn-smoke;
		height: rem-calc(215);
		min-width: rem-calc(300);
		position: relative;
		vertical-align: middle;
		img {
			vertical-align: middle;
			display: inline-block;
		}
	}
}

@include breakpoint(medium only) {
	.front {
		#branding {
			min-height: 460px;
			position: relative;
			bottom: 0;
			left: 0;
			z-index: 2;
			.field-intro {
				p {
					font-size:rem-calc(24);
					line-height: rem-calc(30);
				}
			}
		}
		#row-callout-wrap.container {
			.field-intro {
				margin-bottom: 0;
			}
		}
		#two-up.container {
			margin-top: rem-calc(40);
			bottom: 0;
			padding-bottom: rem-calc(40);
			.block-bean-hp-wise-beyond-her-years-callout .field-intro {
				margin-bottom: 0;
			}
			.large-6 {
				margin-bottom: rem-calc(40);
			}
		}
	}
	.page-node-14 ul.accordion li.accordion-item {
		background: url("../../images/layout/images/accordion-open-btn.png") no-repeat scroll 8px 14px $btn-smoke;
		padding-left: rem-calc(0);
		line-height: rem-calc(36);
	}
	.page-node-14 ul.accordion li.accordion-item.is-active {
		background: url("../../images/layout/images/accordion-close-btn.png") no-repeat scroll 8px 14px $btn-smoke;
		padding-left: rem-calc(36);
		padding-right: rem-calc(15);
	}
	.page-node-14 .qa-answer.is-active {
		padding-left: rem-calc(36);
		padding-right: rem-calc(15);
	}
	.episode-row {
		.episodes-grid-wrapper {
			max-width: 296px;
			margin: 0 auto;
			padding-bottom: rem-calc(100);
		}
	}
}
@include breakpoint(small only) {
	 .page {
	 	#branding {
	 		background: url("../images/pages/images/Home_bg-2017-BW-640.jpg") no-repeat center 75px $white;
	 		background-attachment: fixed;
			position: relative;
			top: 0;
			left: 0;
			z-index: 2;
			min-height: 380px;
	 	}
		 .main {
			 padding-top: rem-calc(48);
			 article {
				 padding-left: rem-calc(20);
				 padding-right: rem-calc(20);
			 }
		 }
	 }
	.episode-row {
		.episodes-grid-wrapper {
			float: none;
		}
	}
	.episodes-tabs {
		padding-right: rem-calc(10);
		padding-left: rem-calc(10);
	}
	.tabs-content {
		padding-right: rem-calc(10);
		padding-left: rem-calc(10);
	}
	.node-type-episode .podcast-row {
		background: url("../images/layout/images/sarah-bg.png") repeat scroll 0 0 #d5c3bb;
		padding-top: rem-calc(0);
		padding-bottom: rem-calc(0);
		margin-bottom: rem-calc(27);
		.panel {
			background-color: #d5c3bb;
			border: solid 1px #d5c3bb;
		}
	}
	.front {
		.field-intro {
			padding-right: rem-calc(10);
			padding-left: rem-calc(10);
			p {
				font-size: rem-calc(22);
				line-height: rem-calc(38);
				-ms-word-break: normal;
				word-break: normal;
				word-break: normal;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
				hyphens: none;
			}
		}
	}
	.field-intro {
		padding-right: rem-calc(10);
		padding-left: rem-calc(10);
		p {
			font-size: rem-calc(22);
			line-height: rem-calc(38);
			-ms-word-break: normal;
			word-break: normal;
			word-break: normal;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: none;
		}
	}
	.page-node-14 {
		.qa-header,
		.accordion .accordion-item > a,
		#row-callout-wrap .sb-panel p {
			font-size: rem-calc(22);
			line-height: rem-calc(38);
		}
	}
	.section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro p {
		font-size: rem-calc(22);
		line-height: rem-calc(38);
	}
	.tabs-content .tabs-panel .episode-link a {
		font-size: rem-calc(18);
		line-height: rem-calc(34);
	}
}
@include breakpoint(xlarge){
	//.front {
	//	.page {
	//		// background: url("../images/pages/images/Home_bg-2016-BW-2560.jpg") no-repeat right 0 $white;
	//	}
	//	#branding {
	//		background: url("../images/pages/images/Home_bg-2016-BW-2560.jpg") no-repeat center 0 $white;
	//		background-position: fixed;
	//		position: relative;
	//		bottom: 102px;
	//		left: 0;
	//		z-index: 2;
	//		padding-top: rem-calc(450);
	//		.field-intro {
	//			margin-bottom: rem-calc(280);
	//		}
	//	}
	//}
}
