.l-footer {
	background-color: $silver;
	margin-top: 0;
	padding-top: rem-calc(55);
	padding-bottom: rem-calc(120);
	.footer {
		@include grid-row();
		.footer-left {
			@include grid-column(6);
		}
		.footer-right {
			@include grid-column(4);
		}
	}
}
#subscribe-callout-wrap {
	// background-color: $red-link;
	margin-top: 0;
	max-height: auto;
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	.panel {
		// background-color: $red-link;
		padding-top: 0;
		padding-bottom: 0;
		border: none;
		margin-bottom: 0;
		padding-right: 0;
		padding-left: 0;
		form {
			.form-required {
				display: none;
			}
			.form-headline {
				padding-bottom: rem-calc(18);
			}
			.subscribe-form-wrapper {
				padding-left: 0;
				padding-right: 0;
			}
			button,
			button:hover,
			button:focus,
			.button:hover,
			.button:focus {
				text-transform: uppercase;
				font-weight: normal;
				@include button(rem-calc(10), $btn-smoke);
				font-size: rem-calc(50);
				line-height: rem-calc(26);
				border-color: $btn-smoke;
				height: rem-calc(63);
				color: $smoke;
			}
			.subscribe-form-wrapper {
				.label-wrap {
					padding-right: 0;
				}
				.field-wrap {
					padding-right: 0;
					padding-left: 0;
					.large-10 {
						padding-right: 0;
					}
					.large-2 {
						padding-right: 0;
						padding-left: 0;
					}
				}
			}
			label {
				color: $white;
				font-family: 'Assistant';
				font-weight: normal;
				font-size: rem-calc(16);
				line-height: rem-calc(25);
				text-transform: uppercase;
				vertical-align: middle;
				cursor: text;
			}
			.form-item-email-address {
				margin-top: 0;
				margin-bottom: 0;
			}
			input,
			input[type="text"] {
				margin-bottom: 0;
				height: rem-calc(63);
				padding-top: rem-calc(16);
				padding-bottom: rem-calc(17);
				background-color: $btn-smoke; //rgba(255, 255, 255, 0.40);
				border-color: $btn-smoke; //rgba(255, 255, 255, 0.40);
				box-shadow: none;
				border-width: 0;
				font-family: 'Rockwell W01';
				color: $white;
				font-size: rem-calc(20);
			}
			.postfix.button {
				margin-bottom: 0;
				height: rem-calc(27);
				background-color: rgba(255, 255, 255, 0.40);
				input {
					background-color: rgba(255, 255, 255, 0);
					padding: 0;
				}

			}
		}
	}
}
#footer-menu {
	margin-bottom: rem-calc(14);
}
.copyright {
	@include grid-column(12);
	color: $white;
	font-family: 'Assistant';
	line-height: rem-calc(24);
	font-size: rem-calc(14);
	text-transform: uppercase;
	font-weight: 400;
	a, a:hover {
		color: $white;
		text-transform: uppercase;
	}
}
.cc-seal {
	margin-top: rem-calc(48);
	a {
	}
}
@include breakpoint(medium only){
	.l-footer {
		#social-nav {
			float: none;
		}
		.footer {
			// @include grid-row();
			.footer-left {
				@include grid-column(12);
			}
			.footer-right {
				@include grid-column(12);
				float:none;
			}
			.copyright {
				@include grid-column(12);
				padding-top: rem-calc(16);
				padding-bottom: rem-calc(16);
			}
		}
	}
}
@include breakpoint(small only) {
	.l-footer {
		#social-nav {
			float: none;
		}
		.footer {
			// @include grid-row();
			.footer-left {
				@include grid-column(12);
				padding-left: rem-calc(20);
			}
			.footer-right {
				@include grid-column(12);
				padding-left: rem-calc(20);
				float:none;
			}
			.copyright {
				@include grid-column(12);
				padding-top: rem-calc(16);
				padding-bottom: rem-calc(16);
			}
		}
	}
}
