.subhead-large {
	font-family: 'Rockwell W01';
	font-size: emCalc(20);
	line-height: 1.2em;
	color: $primary-color;
	text-align: center;
	max-width: 690px;
	margin: 0 auto 27px;
}
.node-type-category-page .subhead-large {
	font-family: 'Museo Slab W01 500';
	font-size: emCalc(16);
	line-height: emCalc(24);
	max-width: 810px;
}
.section-head {
  font-family: 'Assistant', sans-serif;
  text-transform: uppercase;
	//font-size: rem-calc(26);
	//line-height: rem-calc(36);
  font-size: rem-calc(24);
  line-height: rem-calc(54);
	color: $silver;
	margin: 0;
	font-weight: 500;
	display: block;
}
.rteindent1 {
    margin-left: 0;
}
.uppercase {
	text-transform: uppercase;
}
