@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Assistant";
  font-weight: 400;
  line-height: 1.5;
  color: #a29e98;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 61.25rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.25rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 61.25rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.25rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 61.25rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #a29e98;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #8d8881; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 61.25rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #a29e98;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #8c877f;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #a29e98;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #847f77;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #e17860;
    color: #0a0a0a; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #d74a2a;
      color: #0a0a0a; }
  .button.success {
    background-color: #43AC6A;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #368a55;
      color: #0a0a0a; }
  .button.warning {
    background-color: #f08a24;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cf6e0e;
      color: #0a0a0a; }
  .button.alert {
    background-color: #a0d3e8;
    color: #0a0a0a; }
    .button.alert:hover, .button.alert:focus {
      background-color: #61b6d9;
      color: #0a0a0a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #a29e98;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #a29e98;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #e17860;
        color: #0a0a0a; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #43AC6A;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #f08a24;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #a0d3e8;
        color: #0a0a0a; }
  .button.hollow {
    border: 1px solid #a29e98;
    color: #a29e98; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #534f4a;
      color: #534f4a; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #a29e98;
        color: #a29e98; }
    .button.hollow.primary {
      border: 1px solid #a29e98;
      color: #a29e98; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #534f4a;
        color: #534f4a; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #a29e98;
          color: #a29e98; }
    .button.hollow.secondary {
      border: 1px solid #e17860;
      color: #e17860; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #872e19;
        color: #872e19; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #e17860;
          color: #e17860; }
    .button.hollow.success {
      border: 1px solid #43AC6A;
      color: #43AC6A; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #225635;
        color: #225635; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #43AC6A;
          color: #43AC6A; }
    .button.hollow.warning {
      border: 1px solid #f08a24;
      color: #f08a24; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #814509;
        color: #814509; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #f08a24;
          color: #f08a24; }
    .button.hollow.alert {
      border: 1px solid #a0d3e8;
      color: #a0d3e8; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #267b9e;
        color: #267b9e; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #a0d3e8;
          color: #a0d3e8; }
  .button.clear {
    border: 1px solid #a29e98;
    color: #a29e98; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #534f4a;
      color: #534f4a; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #a29e98;
        color: #a29e98; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #a29e98;
      color: #a29e98; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #534f4a;
        color: #534f4a; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #a29e98;
          color: #a29e98; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #e17860;
      color: #e17860; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #872e19;
        color: #872e19; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #e17860;
          color: #e17860; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #43AC6A;
      color: #43AC6A; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #225635;
        color: #225635; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #43AC6A;
          color: #43AC6A; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #f08a24;
      color: #f08a24; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #814509;
        color: #814509; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #f08a24;
          color: #f08a24; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #a0d3e8;
      color: #a0d3e8; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #267b9e;
        color: #267b9e; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #a0d3e8;
          color: #a0d3e8; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #a29e98; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #a29e98; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #e17860; }
  .button.dropdown.hollow.success::after {
    border-top-color: #43AC6A; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #f08a24; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #a0d3e8; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #a0d3e8;
  background-color: #f5fafc; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #a0d3e8; }

.is-invalid-label {
  color: #a0d3e8; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #a0d3e8; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #e0dfdd;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e0dfdd;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #a29e98; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e0dfdd;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e0dfdd;
  border-bottom: 0;
  background-color: #fefefe;
  color: #a29e98; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e0dfdd; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #a29e98 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #a29e98 transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #a29e98;
  color: #fefefe; }
  .badge.primary {
    background: #a29e98;
    color: #0a0a0a; }
  .badge.secondary {
    background: #e17860;
    color: #0a0a0a; }
  .badge.success {
    background: #43AC6A;
    color: #0a0a0a; }
  .badge.warning {
    background: #f08a24;
    color: #0a0a0a; }
  .badge.alert {
    background: #a0d3e8;
    color: #0a0a0a; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #a29e98; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #a29e98;
    color: #0a0a0a; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #847f77;
      color: #0a0a0a; }
  .button-group.secondary .button {
    background-color: #e17860;
    color: #0a0a0a; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #d74a2a;
      color: #0a0a0a; }
  .button-group.success .button {
    background-color: #43AC6A;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #368a55;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #f08a24;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cf6e0e;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #a0d3e8;
    color: #0a0a0a; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #61b6d9;
      color: #0a0a0a; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.45);
  border-radius: 0;
  background-color: white;
  color: #a29e98; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: white;
    color: #a29e98; }
  .callout.secondary {
    background-color: white;
    color: #a29e98; }
  .callout.success {
    background-color: white;
    color: #a29e98; }
  .callout.warning {
    background-color: white;
    color: #a29e98; }
  .callout.alert {
    background-color: white;
    color: #a29e98; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #a29e98; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #fefefe;
    color: #e17860; }
  .menu .active > a {
    background: #fefefe;
    color: #e17860; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #a29e98; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #a29e98; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #a29e98 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #a29e98 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #a29e98 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #a29e98; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #a29e98 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #a29e98; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #a29e98 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #a29e98 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #a29e98; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #a29e98 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #a29e98 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #a29e98; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #a29e98 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #a29e98; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #a29e98;
  color: #fefefe; }
  .label.primary {
    background: #a29e98;
    color: #0a0a0a; }
  .label.secondary {
    background: #e17860;
    color: #0a0a0a; }
  .label.success {
    background: #43AC6A;
    color: #0a0a0a; }
  .label.warning {
    background: #f08a24;
    color: #0a0a0a; }
  .label.alert {
    background: #a0d3e8;
    color: #0a0a0a; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #a29e98;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #a29e98; }
  .progress.secondary .progress-meter {
    background-color: #e17860; }
  .progress.success .progress-meter {
    background-color: #43AC6A; }
  .progress.warning .progress-meter {
    background-color: #f08a24; }
  .progress.alert .progress-meter {
    background-color: #a0d3e8; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #a29e98; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #a29e98;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #8c877f; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 61.25rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 61.25rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 61.25rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 61.25rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #a29e98; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #a29e98; }
  table tfoot {
    background: #f1f1f1;
    color: #a29e98; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #fefefe;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #a29e98; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #9b9690; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1;
    color: #a29e98; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #8d8881; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #fefefe;
      color: #e17860; }

.tabs-content {
  border: 1px solid #fefefe;
  border-top: 0;
  background: #fefefe;
  color: #a29e98;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #fefefe;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 0; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(162, 158, 152, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #fefefe; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.page {
  max-width: 100%;
  overflow: hidden;
  text-rendering: optimizelegibility; }

#page-title {
  margin-bottom: 1.25rem; }

h3 {
  line-height: 2.25rem;
  font-weight: normal; }

p {
  font-size: 1.625rem;
  line-height: 2.75rem;
  margin-bottom: 2.5rem;
  font-weight: 400;
  -ms-word-break: break-word;
  word-break: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

.copy-link {
  text-decoration: none;
  color: #e17860; }

a {
  outline: none; }

.red-link {
  color: #e17860; }

@media screen and (max-width: 39.9375em) {
  p {
    font-size: 1.375rem;
    line-height: 2.375rem; } }

.admin-menu .fixed {
  top: emCalc(29px); }

#admin-menu a,
#admin-menu .dropdown li li a {
  font-size: 0.75rem; }

#status-messages.reveal-modal .alert-box {
  margin-bottom: 0; }

.reveal-modal {
  z-index: 999; }

.tasks-block .button,
.tasks-block .button:hover {
  color: #fefefe; }

.alt-main-menu {
  padding-bottom: 4.9375rem;
  padding-top: 1.375rem; }
  .alt-main-menu li {
    font-size: 1.0625rem;
    line-height: 1.625rem; }
  .alt-main-menu li.first a {
    padding-left: 0.625rem; }
  .alt-main-menu a {
    text-transform: uppercase;
    font-weight: 600; }
  .alt-main-menu li.last {
    padding: 0.625rem 0.75rem; }
  .alt-main-menu .social-btn {
    background-color: #a29e98;
    outline: none;
    padding: 0;
    margin-bottom: 0;
    color: #a29e98;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.25rem;
    text-indent: 9999px;
    height: 1.375rem; }
    .alt-main-menu .social-btn:hover {
      background-color: #a29e98;
      color: #a29e98; }
  .alt-main-menu #instagram-btn {
    background: url("../images/layout/images/sb_ig_icon.png") no-repeat 0 0 #fefefe;
    padding-right: 0;
    width: 1.25rem; }

.logo-wrap {
  margin-top: 19.375rem;
  display: block;
  width: 25%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-left: 75%; }
  @media print, screen and (min-width: 40em) {
    .logo-wrap {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .logo-wrap:last-child:not(:first-child) {
    float: right; }
  .logo-wrap .logo {
    padding-left: 0;
    margin-bottom: -50px; }

.tabs {
  background: transparent;
  border: 0px solid #fefefe; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .title-area {
    position: relative;
    z-index: 1000; }
  .logo-wrap .logo {
    width: 50%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 40em) {
    .logo-wrap .logo {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .logo-wrap .logo:last-child:not(:first-child) {
      float: right; } }

.container {
  width: 100%;
  margin-bottom: 0;
  margin-top: 2.375rem;
  padding-top: 6.0625rem;
  padding-bottom: 3.0625rem; }

.row.full-width {
  max-width: 100%; }

#page-title {
  text-transform: uppercase;
  color: #a29e98;
  font-size: 2.5rem;
  line-height: 2.875rem;
  font-weight: 700; }

.breadcrumbs {
  font-weight: normal;
  margin-bottom: 5rem; }
  .breadcrumbs .first a, .breadcrumbs .first a:hover {
    text-indent: -9999px;
    background: url("../images/layout/images/home-icon.png") no-repeat scroll 0 -2px transparent;
    display: block;
    width: 18px;
    height: 17px; }

.main {
  max-width: 61.25rem;
  margin-right: auto;
  margin-left: auto; }
  .main::before, .main::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .main::after {
    clear: both; }
  .main article {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .main article {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
    .main article:last-child:not(:first-child) {
      float: right; }
  .main article.node-episode-track {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.375rem; }

.field-intro {
  margin-bottom: 3rem; }
  .field-intro p {
    font-size: 1.625rem;
    line-height: 2.75rem;
    font-weight: 400;
    -ms-word-break: break-word;
    word-break: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }

.posted {
  text-transform: uppercase;
  color: #a29e98;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 2.75rem; }

.node-type-episode #branding {
  background: url("../images/pages/images/Home_bg-2017-BW-1440.jpg") no-repeat center top #fefefe;
  padding-bottom: 0; }

.node-type-episode .main {
  padding-bottom: 3.625rem; }

.node-type-episode .featured-wrap {
  width: 33.33333%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  position: relative;
  left: 66.66667%; }
  @media print, screen and (min-width: 40em) {
    .node-type-episode .featured-wrap {
      width: 16.66667%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      position: relative;
      left: 83.33333%; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .node-type-episode .featured-wrap {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) {
      .node-type-episode .featured-wrap:last-child:not(:first-child) {
        float: right; }
      .node-type-episode .featured-wrap .block-bean-itunes-badge {
        margin: 1.875rem auto; } }
  @media print, screen and (min-width: 40em) {
    .node-type-episode .featured-wrap {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .node-type-episode .featured-wrap:last-child:not(:first-child) {
    float: right; }
  .node-type-episode .featured-wrap .block-bean-itunes-badge {
    margin: 10.875rem auto 1.875rem; }

.node-type-episode .podcast-row {
  background-color: #e17860;
  padding-top: 6.0625rem;
  padding-bottom: 4.8125rem;
  margin-bottom: 0; }
  .node-type-episode .podcast-row .panel {
    background-color: #d5c3bb;
    border: solid 1px #d5c3bb; }

.node-type-episode h3.episodes-cromb {
  text-transform: uppercase;
  color: #a29e98;
  font-size: 1.0625rem;
  line-height: 2.75rem;
  font-weight: 600;
  margin-bottom: 0; }

.node-type-episode .field-headline p {
  text-transform: uppercase;
  color: #a29e98;
  font-size: 1.625rem;
  line-height: 2.75rem;
  font-weight: 500;
  margin-bottom: 2.25rem; }

.node-type-episode .block-bean-itunes-badge {
  padding-left: 0.625rem;
  clear: both; }

.node-type-episode #row-callout-wrap {
  background-color: #cbc7c1;
  margin-top: 0; }
  .node-type-episode #row-callout-wrap .field-intro .section-head {
    color: #fefefe;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 400;
    text-transform: uppercase; }
  .node-type-episode #row-callout-wrap .field-intro p {
    color: #fefefe;
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2.75rem; }
  .node-type-episode #row-callout-wrap .field-intro a, .node-type-episode #row-callout-wrap .field-intro a:hover {
    color: #fefefe;
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2.75rem; }
  .node-type-episode #row-callout-wrap .field-intro .episode-cta p {
    margin-bottom: 0; }
    .node-type-episode #row-callout-wrap .field-intro .episode-cta p a, .node-type-episode #row-callout-wrap .field-intro .episode-cta p a:hover {
      color: #e17860;
      font-size: 1.5rem;
      line-height: 2.375rem;
      font-weight: 300;
      border-bottom: solid 2px #e17860; }
  .node-type-episode #row-callout-wrap .field-intro .episode-cta p:last-child {
    margin-bottom: 2.5rem; }

.episodes-tabs {
  margin: 0;
  border: 1px solid #fefefe;
  background: #fefefe;
  list-style-type: none; }
  .episodes-tabs::before, .episodes-tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .episodes-tabs::after {
    clear: both; }
  .episodes-tabs li {
    float: left; }
    .episodes-tabs li > a {
      display: block;
      padding: 1.25rem 1.5rem;
      font-size: 1.0625rem;
      line-height: 1;
      color: #a29e98; }
      .episodes-tabs li > a:hover {
        background: #fefefe;
        color: #8d8881; }
      .episodes-tabs li > a:focus, .episodes-tabs li > a[aria-selected='true'] {
        background: #fefefe;
        color: #e17860; }
    .episodes-tabs li a {
      text-transform: uppercase;
      text-align: left;
      padding-left: 0;
      font-weight: 600; }

.tabs-content {
  border: 1px solid #fefefe;
  border-top: 0;
  background: #fefefe;
  color: #a29e98;
  transition: all 0.5s ease;
  padding-bottom: 6.125rem; }
  .tabs-content .tabs-panel {
    display: none;
    padding: 0; }
    .tabs-content .tabs-panel.is-active {
      display: block; }
    .tabs-content .tabs-panel .episode-link a {
      font-size: 1.5rem;
      line-height: 3.375rem;
      text-transform: uppercase; }

.section-core-values [class*="block-grid-"] > li {
  padding-bottom: 0; }

.section-core-values .container,
.section-quotes .container {
  padding-bottom: 5.125rem; }

.section-core-values .cv-row,
.section-quotes .cv-row {
  width: 50%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.9375rem; }
  @media print, screen and (min-width: 40em) {
    .section-core-values .cv-row,
    .section-quotes .cv-row {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .section-core-values .cv-row:last-child:not(:first-child),
  .section-quotes .cv-row:last-child:not(:first-child) {
    float: right; }

.section-core-values .views-row-3,
.section-quotes .views-row-3 {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .section-core-values .views-row-3,
    .section-quotes .views-row-3 {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .section-core-values .views-row-3:last-child:not(:first-child),
  .section-quotes .views-row-3:last-child:not(:first-child) {
    float: right; }

.section-core-values .views-field-nothing,
.section-quotes .views-field-nothing {
  position: relative;
  visibility: hidden;
  margin: 0 auto; }

.section-core-values .views-field-nothing.show,
.section-quotes .views-field-nothing.show {
  visibility: visible; }

.row .row.cv-row {
  margin-left: 0;
  margin-right: 0; }

.a2a_kit {
  display: block;
  width: 105px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -53px; }

.a2a_default_style a.a2a_button_facebook,
.a2a_default_style a.a2a_button_twitter,
.a2a_default_style a.a2a_button_google_plus {
  padding-left: 6px;
  padding-right: 6px; }

.a2a_default_style a.a2a_button_twitter {
  padding-left: 8px; }

.a2a_default_style a.a2a_button_google_plus {
  padding-right: 0; }

.cv-wrapper {
  min-width: 50%; }

.section-quotes .views-row-3 {
  width: 50%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .section-quotes .views-row-3 {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .section-quotes .views-row-3:last-child:not(:first-child) {
    float: right; }

.section-quotes .large-block-grid-2 > li:nth-of-type(2n+1) {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem; }

.section-quotes .cv-row {
  width: 50%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 1.125rem; }
  @media print, screen and (min-width: 40em) {
    .section-quotes .cv-row {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .section-quotes .cv-row:last-child:not(:first-child) {
    float: right; }

.section-fortytude .field-intro {
  margin-bottom: 0; }

.section-fortytude a.button {
  text-transform: uppercase;
  font-weight: 600; }

.section-fortytude .container {
  padding-bottom: 4.8125rem; }

.section-fortytude .main {
  padding-bottom: 3.625rem; }

.section-fortytude #two-up {
  background-color: #e0dfdd;
  margin-top: 0;
  color: #a29e98;
  padding-bottom: 2rem; }
  .section-fortytude #two-up .first {
    padding-right: 0.75rem; }
  .section-fortytude #two-up .last {
    padding-left: 0.75rem; }
  .section-fortytude #two-up p {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: normal; }
    .section-fortytude #two-up p a, .section-fortytude #two-up p a:hover {
      color: #a29e98;
      font-size: 1rem;
      line-height: 1.625rem;
      font-weight: normal;
      border-bottom: solid 2px #e17860; }

#node-18 .body p a, #node-18 .body p a:hover {
  text-decoration: underline; }

.page-node-14 #row-callout-wrap {
  background-color: #e0dfdd;
  padding-bottom: 6.0625rem; }
  .page-node-14 #row-callout-wrap .sb-panel {
    background-color: #e0dfdd;
    border-width: 0; }
    .page-node-14 #row-callout-wrap .sb-panel p, .page-node-14 #row-callout-wrap .sb-panel span, .page-node-14 #row-callout-wrap .sb-panel ul {
      color: #a29e98;
      font-size: 1.5rem;
      line-height: 2.625rem;
      font-weight: 400;
      margin-bottom: 1.25rem; }
    .page-node-14 #row-callout-wrap .sb-panel ul {
      line-height: 2.625rem; }
      .page-node-14 #row-callout-wrap .sb-panel ul li {
        border-color: #e0dfdd;
        padding-left: 0; }
        .page-node-14 #row-callout-wrap .sb-panel ul li a {
          border-color: #e0dfdd;
          padding-top: 0;
          padding-bottom: 0; }

.page-node-14 .main {
  padding-bottom: 3.625rem; }

.page-node-14 .container {
  margin-top: 0; }

.page-node-14 .qa-header {
  color: #a29e98;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 2.75rem; }

.page-node-14 .accordion .accordion-item > a {
  font-size: 1.5rem;
  line-height: 2.625rem;
  font-weight: 600;
  color: #a29e98;
  background: #e0dfdd;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;
  border: 0px solid #e0dfdd; }

.page-node-14 .accordion .accordion-item {
  padding-left: 0; }
  .page-node-14 .accordion .accordion-item .accordion-content {
    padding: 0.625rem;
    background-color: #e0dfdd; }

.page-node-14 .qa-answer.is-active {
  color: #a29e98;
  padding-top: 0.5rem; }

#branding {
  background: url("../images/pages/images/Home_bg-2017-BW-1440.jpg") no-repeat center top #fefefe;
  background-attachment: fixed;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #fefefe; }

#branding.container {
  margin-top: 0;
  padding-top: 2.875rem; }

.front .l-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99; }

.front .featured-wrap .block-bean-itunes-badge {
  width: 16.66667%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .front .featured-wrap .block-bean-itunes-badge {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .front .featured-wrap .block-bean-itunes-badge:last-child:not(:first-child) {
    float: right; }
  .front .featured-wrap .block-bean-itunes-badge .content .ibadge {
    float: right; }

.front .l-main {
  margin-top: 0;
  padding-top: 0;
  background-position: center bottom; }

.front .field-intro {
  margin-bottom: 6.125rem; }
  .front .field-intro p {
    font-size: 1.625rem;
    line-height: 2.75rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0; }
  .front .field-intro .signature p {
    line-height: 1.625rem; }
  .front .field-intro a, .front .field-intro a:hover {
    font-weight: 400;
    color: #e17860;
    border-bottom: #fefefe 0px solid;
    margin-bottom: 5.125rem; }
  .front .field-intro a.button, .front .field-intro a.button:hover {
    font-weight: 600; }

.front #row-callout-wrap {
  background-color: #e17860;
  margin-top: 0;
  color: #fefefe;
  padding-bottom: 6.0625rem; }
  .front #row-callout-wrap .field-intro {
    margin-bottom: 0; }
    .front #row-callout-wrap .field-intro p {
      text-transform: uppercase;
      line-height: 2.75rem;
      font-size: 1.625rem;
      font-weight: 400;
      margin-bottom: 0;
      color: rgba(254, 254, 254, 0.7); }
      .front #row-callout-wrap .field-intro p a, .front #row-callout-wrap .field-intro p a:hover {
        font-size: 1rem;
        font-weight: 400;
        color: #fefefe;
        border-bottom: #fefefe 2px solid;
        margin-bottom: 5.125rem; }

.front #two-up {
  background: #fefefe;
  margin-top: 0;
  padding-top: 0;
  position: relative;
  bottom: 44px;
  color: #a29e98;
  padding-bottom: 2.6875rem;
  padding-top: 0; }
  .front #two-up .first {
    text-align: center; }
    .front #two-up .first .block-bean-hp-episods-callout {
      background-color: #cbc7c1;
      min-height: 450px; }
      .front #two-up .first .block-bean-hp-episods-callout img {
        margin-top: 2.0625rem;
        padding-bottom: 2.6875rem; }
      .front #two-up .first .block-bean-hp-episods-callout .field-intro {
        max-width: 23.125rem;
        margin: auto;
        padding-bottom: 2.0625rem; }
  .front #two-up .last {
    text-align: center; }
    .front #two-up .last .block-bean-hp-book-callout {
      background-color: #e0dfdd;
      min-height: 450px; }
      .front #two-up .last .block-bean-hp-book-callout img {
        margin-top: 2.0625rem;
        padding-bottom: 1.875rem; }
      .front #two-up .last .block-bean-hp-book-callout .field-intro {
        max-width: 22.625rem;
        margin: auto;
        padding-bottom: 2.0625rem; }
    .front #two-up .last span.em-dash:before {
      content: "\2014";
      display: inline; }
  .front #two-up p {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
    -ms-word-break: break-word;
    word-break: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
    .front #two-up p a, .front #two-up p a:hover {
      color: #a29e98;
      margin-top: 1rem;
      display: inline-block;
      font-size: 1rem;
      line-height: 1.625rem;
      font-weight: 400;
      border-bottom: solid 2px #e17860; }

.front #hp-row-callout-wrap {
  background: #e17860;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 100%; }
  .front #hp-row-callout-wrap .row-callout {
    padding-left: 0;
    padding-right: 0; }
  .front #hp-row-callout-wrap .field-intro {
    margin-bottom: 0; }
  .front #hp-row-callout-wrap .block-bean-home-page-bio {
    width: 58.33333%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-left: 0;
    float: none; }
    @media print, screen and (min-width: 40em) {
      .front #hp-row-callout-wrap .block-bean-home-page-bio {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
    .front #hp-row-callout-wrap .block-bean-home-page-bio:last-child:not(:first-child) {
      float: right; }
    .front #hp-row-callout-wrap .block-bean-home-page-bio .hp-bio-wrapper p {
      color: #fefefe;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 300;
      margin-bottom: 0; }
      .front #hp-row-callout-wrap .block-bean-home-page-bio .hp-bio-wrapper p a, .front #hp-row-callout-wrap .block-bean-home-page-bio .hp-bio-wrapper p a:hover {
        color: #fefefe;
        font-size: 1rem;
        line-height: 1.625rem;
        text-transform: uppercase;
        border-bottom: solid 2px #fefefe;
        font-weight: 300; }

.front #hp-book-callout-wrap {
  margin-top: 0;
  padding-top: 6.0625rem;
  padding-bottom: 3.0625rem; }
  .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-cv-image {
    width: 25%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-left: 0; }
    @media print, screen and (min-width: 40em) {
      .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-cv-image {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
    .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-cv-image:last-child:not(:first-child) {
      float: right; }
  .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro {
    width: 75%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
    .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro:last-child:not(:first-child) {
      float: right; }
    .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro .hp-book-callout p {
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 2.875rem;
      font-weight: 400;
      -ms-word-break: keep-all;
      word-break: keep-all;
      word-break: keep-all;
      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
      margin-bottom: 0;
      padding-right: 1.75rem; }
    .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro .hp-book-callout .signature p:before {
      content: "\2014"; }
    .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro .hp-book-callout .read-link p {
      line-height: 1.625rem;
      font-size: 1.25rem; }
      .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro .hp-book-callout .read-link p a, .front #hp-book-callout-wrap .block-bean-hp-book-callout .field-intro .hp-book-callout .read-link p a:hover {
        color: #a29e98;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;
        border-bottom: solid 2px #e17860;
        font-weight: 400; }

.section-contact #node-4 .field-intro {
  margin-bottom: 4.25rem; }
  .section-contact #node-4 .field-intro p {
    color: #a29e98; }

.section-contact #row-callout-wrap {
  background: #e0dfdd;
  margin-top: 0;
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
  color: #fefefe; }
  .section-contact #row-callout-wrap .field-intro p a, .section-contact #row-callout-wrap .field-intro p a:hover {
    color: #a29e98;
    font-size: 1rem;
    line-height: 1.625rem;
    padding-top: 1.25rem;
    font-weight: 400; }
  .section-contact #row-callout-wrap .block-webform {
    min-height: 28.375rem;
    max-width: 61.25rem;
    margin-right: auto;
    margin-left: auto; }
    .section-contact #row-callout-wrap .block-webform::before, .section-contact #row-callout-wrap .block-webform::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .section-contact #row-callout-wrap .block-webform::after {
      clear: both; }
    .section-contact #row-callout-wrap .block-webform .webform-component-markup p {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2rem; }
    .section-contact #row-callout-wrap .block-webform .secret-wrapper {
      width: 83.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      padding-right: 0;
      padding-left: 0;
      margin-top: 0; }
      @media print, screen and (min-width: 40em) {
        .section-contact #row-callout-wrap .block-webform .secret-wrapper {
          padding-right: 0.625rem;
          padding-left: 0.625rem; } }
      .section-contact #row-callout-wrap .block-webform .secret-wrapper:last-child:not(:first-child) {
        float: right; }
      .section-contact #row-callout-wrap .block-webform .secret-wrapper .form-textarea-wrapper {
        height: 3.875rem; }
        .section-contact #row-callout-wrap .block-webform .secret-wrapper .form-textarea-wrapper .contact-secret {
          max-height: 3.875rem;
          background-color: rgba(254, 254, 254, 0.7);
          color: #a29e98;
          font-size: 1.5rem;
          font-weight: normal; }
    .section-contact #row-callout-wrap .block-webform .webform-submit {
      width: 16.66667%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 0;
      height: 3.875rem;
      text-transform: uppercase;
      font-weight: normal; }
      @media print, screen and (min-width: 40em) {
        .section-contact #row-callout-wrap .block-webform .webform-submit {
          padding-right: 0.625rem;
          padding-left: 0.625rem; } }
      .section-contact #row-callout-wrap .block-webform .webform-submit:last-child:not(:first-child) {
        float: right; }
  .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro {
    margin-bottom: 0; }
    .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro p {
      font-size: 1.625rem;
      line-height: 2.75rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
      color: #a29e98; }
    .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro .contact-links p {
      font-size: 1rem;
      line-height: 1.625rem;
      margin-bottom: 0; }
    .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro a, .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro a:hover {
      color: #a29e98;
      font-size: 1.0625rem;
      line-height: 1.625rem;
      text-transform: uppercase;
      text-align: left;
      padding-left: 0; }
    .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro a:hover {
      color: #e17860; }

.page-node-2 .container {
  padding-bottom: 6.0625rem; }

.page-node-2 .body p {
  text-transform: uppercase;
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0; }
  .page-node-2 .body p a, .page-node-2 .body p a:hover {
    color: #a29e98;
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 400;
    border-bottom: solid 2px #e17860;
    text-decoration: none; }

.block-views-articles-block .section-head {
  font-size: 1.75rem;
  line-height: 2.25rem; }

.block-views-articles-block .field-intro p {
  -moz-hyphens: auto;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 300; }
  .block-views-articles-block .field-intro p a, .block-views-articles-block .field-intro p a:hover {
    text-decoration: underline;
    display: inline-block;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }

.page-node-5 .container {
  padding-bottom: 6.0625rem; }

.view-exercises .red-link a, .view-exercises .red-link a:hover {
  color: #e17860;
  font-size: 1.75rem;
  line-height: 2.75rem;
  border-bottom: solid 2px #e17860;
  display: inline-block;
  font-weight: 400; }

.page-node-10 .exercise-bot {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.section-thenotsosecretsociety .container {
  padding-bottom: 4.8125rem; }

.tnsss-grid {
  text-align: center; }

.thumbnail {
  position: relative;
  overflow: hidden;
  padding: 0;
  border-radius: 0;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  float: none; }

.caption {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background: rgba(213, 195, 187, 0.7);
  width: 296px;
  height: 307px;
  display: none;
  text-align: center;
  color: #fff !important;
  z-index: 2; }
  .caption .caption-inner {
    display: table; }
    .caption .caption-inner .field-tnsss-quote {
      padding-top: 12%;
      width: 280px;
      height: 307px;
      margin: 0 auto;
      vertical-align: middle;
      display: table-cell; }
      .caption .caption-inner .field-tnsss-quote p {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.1875rem;
        margin-bottom: 0.375rem;
        text-align: left;
        vertical-align: middle;
        padding: 0 0.3125rem 0 1.1875rem; }
      .caption .caption-inner .field-tnsss-quote .signature {
        vertical-align: middle;
        display: table-row; }
        .caption .caption-inner .field-tnsss-quote .signature p {
          font-size: 1rem;
          line-height: 1.1875rem; }

.section-media #page-title {
  display: none; }

.block-views-media-gallery-block {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 6.25rem; }
  @media print, screen and (min-width: 40em) {
    .block-views-media-gallery-block {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .block-views-media-gallery-block:last-child:not(:first-child) {
    float: right; }

.media-wrapper {
  text-align: center;
  padding-left: 0; }
  .media-wrapper .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .media-wrapper .ff-preview {
    background-color: #fefefe;
    border: 1px solid #e0dfdd;
    height: 13.4375rem;
    min-width: 18.75rem;
    position: relative;
    vertical-align: middle; }
    .media-wrapper .ff-preview img {
      vertical-align: middle;
      display: inline-block; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .front #branding {
    min-height: 460px;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 2; }
    .front #branding .field-intro p {
      font-size: 1.5rem;
      line-height: 1.875rem; }
  .front #row-callout-wrap.container .field-intro {
    margin-bottom: 0; }
  .front #two-up.container {
    margin-top: 2.5rem;
    bottom: 0;
    padding-bottom: 2.5rem; }
    .front #two-up.container .block-bean-hp-wise-beyond-her-years-callout .field-intro {
      margin-bottom: 0; }
    .front #two-up.container .large-6 {
      margin-bottom: 2.5rem; }
  .page-node-14 ul.accordion li.accordion-item {
    background: url("../../images/layout/images/accordion-open-btn.png") no-repeat scroll 8px 14px #e0dfdd;
    padding-left: 0;
    line-height: 2.25rem; }
  .page-node-14 ul.accordion li.accordion-item.is-active {
    background: url("../../images/layout/images/accordion-close-btn.png") no-repeat scroll 8px 14px #e0dfdd;
    padding-left: 2.25rem;
    padding-right: 0.9375rem; }
  .page-node-14 .qa-answer.is-active {
    padding-left: 2.25rem;
    padding-right: 0.9375rem; }
  .episode-row .episodes-grid-wrapper {
    max-width: 296px;
    margin: 0 auto;
    padding-bottom: 6.25rem; } }

@media screen and (max-width: 39.9375em) {
  .page #branding {
    background: url("../images/pages/images/Home_bg-2017-BW-640.jpg") no-repeat center 75px #fefefe;
    background-attachment: fixed;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    min-height: 380px; }
  .page .main {
    padding-top: 3rem; }
    .page .main article {
      padding-left: 1.25rem;
      padding-right: 1.25rem; }
  .episode-row .episodes-grid-wrapper {
    float: none; }
  .episodes-tabs {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .tabs-content {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .node-type-episode .podcast-row {
    background: url("../images/layout/images/sarah-bg.png") repeat scroll 0 0 #d5c3bb;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1.6875rem; }
    .node-type-episode .podcast-row .panel {
      background-color: #d5c3bb;
      border: solid 1px #d5c3bb; }
  .front .field-intro {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    .front .field-intro p {
      font-size: 1.375rem;
      line-height: 2.375rem;
      -ms-word-break: normal;
      word-break: normal;
      word-break: normal;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: none; }
  .field-intro {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    .field-intro p {
      font-size: 1.375rem;
      line-height: 2.375rem;
      -ms-word-break: normal;
      word-break: normal;
      word-break: normal;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: none; }
  .page-node-14 .qa-header,
  .page-node-14 .accordion .accordion-item > a,
  .page-node-14 #row-callout-wrap .sb-panel p {
    font-size: 1.375rem;
    line-height: 2.375rem; }
  .section-contact #row-callout-wrap .block-bean-contact-bottom .field-intro p {
    font-size: 1.375rem;
    line-height: 2.375rem; }
  .tabs-content .tabs-panel .episode-link a {
    font-size: 1.125rem;
    line-height: 2.125rem; } }

.l-footer {
  background-color: #a29e98;
  margin-top: 0;
  padding-top: 3.4375rem;
  padding-bottom: 7.5rem; }
  .l-footer .footer {
    max-width: 61.25rem;
    margin-right: auto;
    margin-left: auto; }
    .l-footer .footer::before, .l-footer .footer::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .l-footer .footer::after {
      clear: both; }
    .l-footer .footer .footer-left {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; }
      @media print, screen and (min-width: 40em) {
        .l-footer .footer .footer-left {
          padding-right: 0.625rem;
          padding-left: 0.625rem; } }
      .l-footer .footer .footer-left:last-child:not(:first-child) {
        float: right; }
    .l-footer .footer .footer-right {
      width: 33.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; }
      @media print, screen and (min-width: 40em) {
        .l-footer .footer .footer-right {
          padding-right: 0.625rem;
          padding-left: 0.625rem; } }
      .l-footer .footer .footer-right:last-child:not(:first-child) {
        float: right; }

#subscribe-callout-wrap {
  margin-top: 0;
  max-height: auto;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0; }
  #subscribe-callout-wrap .panel {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0; }
    #subscribe-callout-wrap .panel form .form-required {
      display: none; }
    #subscribe-callout-wrap .panel form .form-headline {
      padding-bottom: 1.125rem; }
    #subscribe-callout-wrap .panel form .subscribe-form-wrapper {
      padding-left: 0;
      padding-right: 0; }
    #subscribe-callout-wrap .panel form button,
    #subscribe-callout-wrap .panel form button:hover,
    #subscribe-callout-wrap .panel form button:focus,
    #subscribe-callout-wrap .panel form .button:hover,
    #subscribe-callout-wrap .panel form .button:focus {
      text-transform: uppercase;
      font-weight: normal;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      font-family: inherit;
      padding: 0.85em 1em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 0;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 0.9rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #e0dfdd;
      color: #fefefe;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      font-size: 3.125rem;
      line-height: 1.625rem;
      border-color: #e0dfdd;
      height: 3.9375rem;
      color: #c0bebb; }
      [data-whatinput='mouse'] #subscribe-callout-wrap .panel form button, [data-whatinput='mouse']
      #subscribe-callout-wrap .panel form button:hover, [data-whatinput='mouse']
      #subscribe-callout-wrap .panel form button:focus, [data-whatinput='mouse']
      #subscribe-callout-wrap .panel form .button:hover, [data-whatinput='mouse']
      #subscribe-callout-wrap .panel form .button:focus {
        outline: 0; }
      #subscribe-callout-wrap .panel form button:hover, #subscribe-callout-wrap .panel form button:focus,
      #subscribe-callout-wrap .panel form button:hover:hover,
      #subscribe-callout-wrap .panel form button:hover:focus,
      #subscribe-callout-wrap .panel form button:focus:hover,
      #subscribe-callout-wrap .panel form button:focus:focus,
      #subscribe-callout-wrap .panel form .button:hover:hover,
      #subscribe-callout-wrap .panel form .button:hover:focus,
      #subscribe-callout-wrap .panel form .button:focus:hover,
      #subscribe-callout-wrap .panel form .button:focus:focus {
        background-color: #8c877f;
        color: #fefefe; }
    #subscribe-callout-wrap .panel form .subscribe-form-wrapper .label-wrap {
      padding-right: 0; }
    #subscribe-callout-wrap .panel form .subscribe-form-wrapper .field-wrap {
      padding-right: 0;
      padding-left: 0; }
      #subscribe-callout-wrap .panel form .subscribe-form-wrapper .field-wrap .large-10 {
        padding-right: 0; }
      #subscribe-callout-wrap .panel form .subscribe-form-wrapper .field-wrap .large-2 {
        padding-right: 0;
        padding-left: 0; }
    #subscribe-callout-wrap .panel form label {
      color: #fefefe;
      font-family: 'Assistant';
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5625rem;
      text-transform: uppercase;
      vertical-align: middle;
      cursor: text; }
    #subscribe-callout-wrap .panel form .form-item-email-address {
      margin-top: 0;
      margin-bottom: 0; }
    #subscribe-callout-wrap .panel form input,
    #subscribe-callout-wrap .panel form input[type="text"] {
      margin-bottom: 0;
      height: 3.9375rem;
      padding-top: 1rem;
      padding-bottom: 1.0625rem;
      background-color: #e0dfdd;
      border-color: #e0dfdd;
      box-shadow: none;
      border-width: 0;
      font-family: 'Rockwell W01';
      color: #fefefe;
      font-size: 1.25rem; }
    #subscribe-callout-wrap .panel form .postfix.button {
      margin-bottom: 0;
      height: 1.6875rem;
      background-color: rgba(255, 255, 255, 0.4); }
      #subscribe-callout-wrap .panel form .postfix.button input {
        background-color: rgba(255, 255, 255, 0);
        padding: 0; }

#footer-menu {
  margin-bottom: 0.875rem; }

.copyright {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  color: #fefefe;
  font-family: 'Assistant';
  line-height: 1.5rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 400; }
  @media print, screen and (min-width: 40em) {
    .copyright {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .copyright:last-child:not(:first-child) {
    float: right; }
  .copyright a, .copyright a:hover {
    color: #fefefe;
    text-transform: uppercase; }

.cc-seal {
  margin-top: 3rem; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .l-footer #social-nav {
    float: none; }
  .l-footer .footer .footer-left {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 40em) {
    .l-footer .footer .footer-left {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .l-footer .footer .footer-left:last-child:not(:first-child) {
      float: right; }
  .l-footer .footer .footer-right {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    float: none; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 40em) {
    .l-footer .footer .footer-right {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .l-footer .footer .footer-right:last-child:not(:first-child) {
      float: right; }
  .l-footer .footer .copyright {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-top: 1rem;
    padding-bottom: 1rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (min-width: 40em) {
    .l-footer .footer .copyright {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .l-footer .footer .copyright:last-child:not(:first-child) {
      float: right; } }

@media screen and (max-width: 39.9375em) {
  .l-footer #social-nav {
    float: none; }
  .l-footer .footer .footer-left {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-left: 1.25rem; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    .l-footer .footer .footer-left {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
    .l-footer .footer .footer-left:last-child:not(:first-child) {
      float: right; }
  .l-footer .footer .footer-right {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-left: 1.25rem;
    float: none; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    .l-footer .footer .footer-right {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
    .l-footer .footer .footer-right:last-child:not(:first-child) {
      float: right; }
  .l-footer .footer .copyright {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    padding-top: 1rem;
    padding-bottom: 1rem; } }
  @media screen and (max-width: 39.9375em) and (min-width: 40em) {
    .l-footer .footer .copyright {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
    .l-footer .footer .copyright:last-child:not(:first-child) {
      float: right; } }

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #a29e98;
  height: 75px;
  margin-bottom: 75px;
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 4; }
  .nav-bar .nav-bar-logo {
    padding: 0; }
  .nav-bar li {
    background: #a29e98; }
  .nav-bar a {
    color: #fefefe;
    transition: color 0.2s ease-in; }
    .nav-bar a:hover {
      color: white; }
  .nav-bar a.mobile-logo-wrap {
    max-width: 220px;
    display: inline-block; }
  .nav-bar .offcanvas-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    color: #fefefe;
    text-transform: uppercase;
    background-color: #a29e98;
    transition: background-color 0.2s ease-in;
    cursor: pointer; }
    .nav-bar .offcanvas-trigger .offcanvas-trigger-text {
      margin-right: 0.25rem;
      margin-top: 0.25rem; }
    .nav-bar .offcanvas-trigger:hover {
      background-color: #a29e98; }

.multilevel-offcanvas {
  background: #96918b;
  padding: 50px 20px;
  color: #fefefe; }
  .multilevel-offcanvas > .menu {
    margin-bottom: 1rem; }
  .multilevel-offcanvas .menu a {
    color: rgba(254, 254, 254, 0.6);
    padding-top: 7px;
    padding-bottom: 7px;
    transition: color 0.2s ease-in; }
    .multilevel-offcanvas .menu a:hover {
      color: #fefefe; }
  .multilevel-offcanvas #main-menu a,
  .multilevel-offcanvas .drilldown a {
    background: #96918b; }
  .multilevel-offcanvas .fa {
    color: #fefefe; }
  .multilevel-offcanvas .close-button {
    outline: 0;
    z-index: 7;
    color: #fefefe; }
  .multilevel-offcanvas .is-accordion-submenu-parent > a::after {
    border-color: rgba(254, 254, 254, 0.6) transparent transparent; }
  .multilevel-offcanvas .social-links {
    margin-left: 20px; }
    .multilevel-offcanvas .social-links li {
      margin-right: 5px; }
    .multilevel-offcanvas .social-links a:hover .fa {
      color: #bebebe;
      transition: color .2s ease-in; }
    .multilevel-offcanvas .social-links .fa {
      font-size: 2.2rem; }

.body-info {
  text-align: center;
  color: #a29e98; }
  .body-info .button {
    margin: 1rem 0;
    padding: 0.85em 2em; }

.is-open + .off-canvas-content .hamburger .line:nth-child(1),
.is-open + .off-canvas-content .hamburger .line:nth-child(3) {
  width: 12px; }

.is-open + .off-canvas-content .hamburger .line:nth-child(1) {
  transform: translateX(-5px) rotate(-45deg); }

.is-open + .off-canvas-content .hamburger .line:nth-child(3) {
  transform: translateX(-5px) rotate(45deg); }

.hamburger .line {
  width: 20px;
  height: 2px;
  background-color: #fefefe;
  display: block;
  margin: 3px auto;
  transition: all 0.3s ease-in-out; }
  .hamburger .line:last-child {
    margin-bottom: 0; }

#social-nav {
  width: 50%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 151px;
  float: right; }
  @media print, screen and (min-width: 40em) {
    #social-nav {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  #social-nav:last-child:not(:first-child) {
    float: right; }

.social-bar-section .menu {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-right: 0;
  right: 0; }
  .social-bar-section .menu::before, .social-bar-section .menu::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .social-bar-section .menu::after {
    clear: both; }
  .social-bar-section .menu .social-btn {
    margin: 0;
    margin-right: 15;
    margin-bottom: 15;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .social-bar-section .menu .social-btn:last-child {
      margin-right: 0; }
  .social-bar-section .menu li {
    display: inline-block;
    padding: 0 0 0 2.3125rem; }
  .social-bar-section .menu li.first {
    padding-left: 0; }
  .social-bar-section .menu li.last {
    padding-right: 0; }
  .social-bar-section .menu .social-btn {
    background-color: #a29e98;
    outline: none;
    padding: 0;
    margin-bottom: 0;
    color: #a29e98;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.25rem;
    text-indent: 9999px;
    height: 1.375rem; }
    .social-bar-section .menu .social-btn:hover {
      background-color: #a29e98;
      color: #a29e98; }

#fb-btn {
  background: url("../images/layout/images/menu-social-icon-bar.png") no-repeat 0 0 #c0bebb;
  width: 1.1875rem; }

#twitter-btn {
  background: url("../images/layout/images/menu-social-icon-bar.png") no-repeat -113px 0 #c0bebb;
  width: 1.4375rem; }

#instagram-btn {
  background: url("../images/layout/images/menu-social-icon-bar.png") no-repeat -57px 0 #c0bebb;
  padding-right: 0;
  width: 1.25rem; }

@media screen and (max-width: 39.9375em) {
  .nav-bar {
    margin-bottom: 0; } }

.subhead-large {
  font-family: 'Rockwell W01';
  font-size: emCalc(20);
  line-height: 1.2em;
  color: #a29e98;
  text-align: center;
  max-width: 690px;
  margin: 0 auto 27px; }

.node-type-category-page .subhead-large {
  font-family: 'Museo Slab W01 500';
  font-size: emCalc(16);
  line-height: emCalc(24);
  max-width: 810px; }

.section-head {
  font-family: 'Assistant', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 3.375rem;
  color: #a29e98;
  margin: 0;
  font-weight: 500;
  display: block; }

.rteindent1 {
  margin-left: 0; }

.uppercase {
  text-transform: uppercase; }

ul.graphic {
  list-style-type: none;
  padding-left: 0px;
  margin-left: 0px; }

/* background-image-based CSS3 example */
ul.graphic {
  list-style-type: none;
  margin: 0px;
  padding: 0px; }

ul.graphic li {
  margin-bottom: 0;
  border-bottom: solid 5px #fefefe;
  background-color: #e17860;
  height: 7.5rem;
  margin-left: 0;
  padding-top: 1.875rem;
  padding-bottom: 0.75rem; }
  ul.graphic li:hover {
    background-color: #f88b72; }

.track-wrapper,
.episode-number {
  width: 66.66667%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .track-wrapper,
    .episode-number {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .track-wrapper:last-child:not(:first-child),
  .episode-number:last-child:not(:first-child) {
    float: right; }

ul.graphic li a,
ul.graphic li a.sm2_link,
.episode-number {
  /* assume all items will be sounds rather than wait for onload etc. in this example.. may differ for your uses. */
  display: inline-block;
  padding-left: 22px;
  min-height: 42px;
  vertical-align: middle;
  border-radius: 3px;
  padding: 0 3px 0 80px;
  min-width: 19em;
  _width: 19em;
  /* IE 6 */
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.625rem;
  line-height: 2.375rem;
  font-weight: normal;
  color: #fefefe; }

.episode-number {
  font-size: 1rem;
  line-height: 0.5rem; }

ul.graphic li a.sm2_link {
  transition: background-color 0.1s linear; }

ul.graphic li a,
ul.graphic li a.sm2_paused:hover,
ul.graphic li a.sm2_link:hover {
  background-image: url(../images/player/images/ic_play.svg);
  background-position: 3px 50%;
  background-repeat: no-repeat;
  _background-image: url(../images/player/images/icon_play.gif);
  /* IE 6 */ }

ul.graphic li a.sm2_link:hover {
  /* default hover color, if you'd like.. */
  color: #fff; }

ul.graphic li a.sm2_paused:hover {
  background: transparent url(../images/player/images/ic_play.svg) no-repeat 3px 50%;
  _background-image: url(../images/player/images/icon_play.gif); }

ul.graphic li a.sm2_playing,
ul.graphic li a.sm2_playing:hover {
  background: transparent url(../images/player/images/ic_pause.svg) no-repeat 3px 50%;
  _background-image: url(../images/player/images/ic_pause.svg);
  text-decoration: none; }

ul.graphic li {
  padding-top: 1.125rem; }

.track-wrapper,
.episode-number {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .track-wrapper,
    .episode-number {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .track-wrapper:last-child:not(:first-child),
  .episode-number:last-child:not(:first-child) {
    float: right; }

ul.graphic li a, ul.graphic li a.sm2_link,
.episode-number {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 3.75rem; }

.episode-number {
  font-size: 1rem;
  line-height: 1.125rem;
  padding-left: 3.75rem; }

.sm2-bar-ui {
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: helvetica, arial, verdana, sans-serif;
  font-weight: normal;
  /* prevent background border bleed */
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* because indeed, fonts do look pretty "fat" otherwise in this case. */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* general font niceness? */
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  min-width: 20em;
  max-width: 30em;
  /* take out overflow if you want an absolutely-positioned playlist dropdown. */
  border-radius: 2px;
  overflow: hidden;
  /* just for fun (animate normal / full-width) */
  transition: max-width 0.2s ease-in-out; }

.sm2-bar-ui .sm2-playlist li {
  text-align: center;
  margin-top: -2px;
  font-size: 95%;
  line-height: 1em; }

.sm2-bar-ui.compact {
  min-width: 1em;
  max-width: 15em; }

.sm2-bar-ui ul {
  line-height: 1em; }

/* want things left-aligned? */
.sm2-bar-ui.left .sm2-playlist li {
  text-align: left; }

.sm2-bar-ui .sm2-playlist li .load-error {
  cursor: help; }

.sm2-bar-ui.full-width {
  max-width: 100%;
  z-index: 5; }

.sm2-bar-ui.fixed {
  position: fixed;
  top: auto;
  bottom: 0px;
  left: 0px;
  border-radius: 0px;
  /* so the absolutely-positioned playlist can show... */
  overflow: visible;
  /* and this should probably have a high z-index. tweak as needed. */
  z-index: 999; }

.sm2-bar-ui.fixed .bd,
.sm2-bar-ui.bottom .bd {
  /* display: table; */
  border-radius: 0px;
  border-bottom: none; }

.sm2-bar-ui.bottom {
  /* absolute bottom-aligned UI */
  top: auto;
  bottom: 0px;
  left: 0px;
  border-radius: 0px;
  /* so the absolutely-positioned playlist can show... */
  overflow: visible; }

.sm2-bar-ui.playlist-open .bd {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-color: transparent; }

.sm2-bar-ui .bd,
.sm2-bar-ui .sm2-extra-controls {
  position: relative;
  background-color: #2288cc;
  /*
  transition: background 0.2s ease-in-out;
  */ }

.sm2-bar-ui .sm2-inline-gradient {
  /* gradient */
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.125) 5%, rgba(255, 255, 255, 0.125) 45%, rgba(255, 255, 255, 0.15) 50%, rgba(0, 0, 0, 0.1) 51%, rgba(0, 0, 0, 0.1) 95%);
  /* W3C */ }

.sm2-bar-ui.flat .sm2-inline-gradient {
  background-image: none; }

.sm2-bar-ui.flat .sm2-box-shadow {
  display: none;
  box-shadow: none; }

.sm2-bar-ui.no-volume .sm2-volume {
  /* mobile devices (android + iOS) ignore attempts to set volume. */
  display: none; }

.sm2-bar-ui.textured .sm2-inline-texture {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* for example */
  /* background-image: url(../image/wood_pattern_dark.png); */
  /* additional opacity effects can be applied here. */
  opacity: 0.75; }

.sm2-bar-ui.textured.dark-text .sm2-inline-texture {
  /* dark text + textured case: use light wood background (for example.) */
  /* background-image: url(../image/patterns/wood_pattern.png); */ }

.sm2-bar-ui.textured.dark-text .sm2-playlist-wrapper {
  /* dark text + textured case: ditch 10% dark on playlist body. */
  background-color: transparent; }

.sm2-bar-ui.textured.dark-text .sm2-playlist-wrapper ul li:hover a,
.sm2-bar-ui.textured.dark-text .sm2-playlist-wrapper ul li.selected a {
  /* dark + textured case: dark highlights */
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../images/player/black-10.png);
  /* modern browsers don't neeed the image */
  background-image: none, none; }

.sm2-bar-ui .bd {
  display: table;
  border-bottom: none; }

.sm2-bar-ui .sm2-playlist-wrapper {
  background-color: rgba(0, 0, 0, 0.1); }

.sm2-bar-ui .sm2-extra-controls .bd {
  background-color: rgba(0, 0, 0, 0.2); }

.sm2-bar-ui.textured .sm2-extra-controls .bd {
  /* lighten extra color overlays */
  background-color: rgba(0, 0, 0, 0.05); }

.sm2-bar-ui .sm2-extra-controls {
  background-color: transparent;
  border: none; }

.sm2-bar-ui .sm2-extra-controls .bd {
  /* override full-width table behaviour */
  display: block;
  border: none; }

.sm2-bar-ui .sm2-extra-controls .sm2-inline-element {
  display: inline-block; }

.sm2-bar-ui,
.sm2-bar-ui .bd a {
  color: #fff; }

.sm2-bar-ui.dark-text,
.sm2-bar-ui.dark-text .bd a {
  color: #000; }

.sm2-bar-ui.dark-text .sm2-inline-button {
  /* Warning/disclaimer: SVG might be fuzzy when inverted on Chrome, losing resolution on hi-DPI displays. */
  -webkit-filter: invert(1);
  /* SVG-based invert filter for Firefox */
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='invert'><feColorMatrix in='SourceGraphic' type='matrix' values='-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0'/></filter></svg>#invert");
  /* IE 8 inverse filter, may only match pure black/white */
  /* filter: xray; */
  /* pending W3 standard */
  filter: invert(1);
  /* not you, IE < 10. */
  filter: none\9; }

.sm2-bar-ui .bd a {
  text-decoration: none; }

.sm2-bar-ui .bd .sm2-button-element:hover {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../images/player/black-10.png);
  background-image: none, none; }

.sm2-bar-ui .bd .sm2-button-element:active {
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(../images/player/black-25.png);
  background-image: none, none; }

.sm2-bar-ui .bd .sm2-extra-controls .sm2-button-element:active .sm2-inline-button,
.sm2-bar-ui .bd .active .sm2-inline-button {
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -webkit-transform-origin: 50% 50%;
  /* firefox doesn't scale quite right. */
  transform: scale(0.9);
  transform-origin: 50% 50%;
  /* firefox doesn't scale quite right. */
  -moz-transform: none; }

.sm2-bar-ui .bd .sm2-extra-controls .sm2-button-element:hover,
.sm2-bar-ui .bd .sm2-extra-controls .sm2-button-element:active,
.sm2-bar-ui .bd .active {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../images/player/black-10.png);
  background-image: none, none; }

.sm2-bar-ui .bd .sm2-extra-controls .sm2-button-element:active {
  /* box shadow is excessive on smaller elements. */
  box-shadow: none; }

.sm2-bar-ui {
  /* base font size */
  font-size: 15px;
  text-shadow: none; }

.sm2-bar-ui .sm2-inline-element {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
  overflow: hidden; }

.sm2-bar-ui .sm2-inline-element,
.sm2-bar-ui .sm2-button-element .sm2-button-bd {
  position: relative;
  /**
   * .sm2-button-bd exists because of a Firefox bug from 2000
   * re: nested relative / absolute elements inside table cells.
   * https://bugzilla.mozilla.org/show_bug.cgi?id=63895
   */ }

.sm2-bar-ui .sm2-inline-element,
.sm2-bar-ui .sm2-button-element .sm2-button-bd {
  /**
   * if you play with UI width/height, these are the important ones.
   * NOTE: match these values if you want square UI buttons.
   */
  min-width: 2.8em;
  min-height: 2.8em; }

.sm2-bar-ui .sm2-inline-button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.sm2-bar-ui .sm2-extra-controls .bd {
  /* don't double-layer. */
  background-image: none;
  background-color: rgba(0, 0, 0, 0.15); }

.sm2-bar-ui .sm2-extra-controls .sm2-inline-element {
  width: 25px;
  /* bare minimum */
  min-height: 1.75em;
  min-width: 2.5em; }

.sm2-bar-ui .sm2-inline-status {
  line-height: 100%;
  /* how much to allow before truncating song artist / title with ellipsis */
  display: inline-block;
  min-width: 200px;
  max-width: 20em;
  /* a little more spacing */
  padding-left: 0.75em;
  padding-right: 0.75em; }

.sm2-bar-ui .sm2-inline-element {
  /* extra-small em scales up nicely, vs. 1px which gets fat */
  border-right: 0.075em dotted #666;
  /* legacy */
  border-right: 0.075em solid rgba(0, 0, 0, 0.1); }

.sm2-bar-ui .sm2-inline-element.noborder {
  border-right: none; }

.sm2-bar-ui .sm2-inline-element.compact {
  min-width: 2em;
  padding: 0px 0.25em; }

.sm2-bar-ui .sm2-inline-element:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden; }

.sm2-bar-ui .sm2-inline-element:last-of-type {
  border-right: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.sm2-bar-ui .sm2-inline-status a:hover {
  background-color: transparent;
  text-decoration: underline; }

.sm2-inline-time,
.sm2-inline-duration {
  display: table-cell;
  width: 1%;
  font-size: 75%;
  line-height: 0.9em;
  min-width: 2em;
  /* if you have sounds > 10:00 in length, make this bigger. */
  vertical-align: middle; }

.sm2-bar-ui .sm2-playlist {
  position: relative;
  height: 1.45em; }

.sm2-bar-ui .sm2-playlist-target {
  /* initial render / empty case */
  position: relative;
  min-height: 1em; }

.sm2-bar-ui .sm2-playlist ul {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  list-style-type: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.sm2-bar-ui p,
.sm2-bar-ui .sm2-playlist ul,
.sm2-bar-ui .sm2-playlist ul li {
  margin: 0px;
  padding: 0px; }

.sm2-bar-ui .sm2-playlist ul li {
  position: relative; }

.sm2-bar-ui .sm2-playlist ul li,
.sm2-bar-ui .sm2-playlist ul li a {
  position: relative;
  display: block;
  /* prevent clipping of characters like "g" */
  height: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; }

.sm2-row {
  position: relative;
  display: table-row; }

.sm2-progress-bd {
  /* spacing between progress track/ball and time (position) */
  padding: 0px 0.8em; }

.sm2-progress .sm2-progress-track,
.sm2-progress .sm2-progress-ball,
.sm2-progress .sm2-progress-bar {
  position: relative;
  width: 100%;
  height: 0.65em;
  border-radius: 0.65em; }

.sm2-progress .sm2-progress-bar {
  /* element which follows the progres "ball" as it moves */
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  background-color: rgba(0, 0, 0, 0.33);
  background-image: url(../images/player/black-33.png);
  background-image: none, none; }

.volume-shade,
.playing .sm2-progress .sm2-progress-track,
.paused .sm2-progress .sm2-progress-track {
  cursor: pointer; }

.playing .sm2-progress .sm2-progress-ball {
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab; }

.sm2-progress .sm2-progress-ball {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1em;
  height: 1em;
  margin: -0.2em 0px 0px -0.5em;
  width: 14px;
  height: 14px;
  margin: -2px 0px 0px -7px;
  width: 0.9333em;
  height: 0.9333em;
  margin: -0.175em 0px 0px -0.466em;
  background-color: #fff;
  padding: 0px;
  /*
   z-index: 1;
  */
  transition: transform 0.15s ease-in-out; }

/*
.sm2-bar-ui.dark-text .sm2-progress .sm2-progress-ball {
 background-color: #000;
}
*/
.sm2-progress .sm2-progress-track {
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(../images/player/black-33.png);
  /* legacy */
  background-image: none, none;
  /* modern browsers */ }

/* scrollbar rules have to be separate, browsers not supporting this syntax will skip them when combined. */
.sm2-playlist-wrapper ul::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4); }

.playing.grabbing .sm2-progress .sm2-progress-track,
.playing.grabbing .sm2-progress .sm2-progress-ball {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.sm2-bar-ui.grabbing .sm2-progress .sm2-progress-ball {
  -webkit-transform: scale(1.15);
  transform: scale(1.15); }

.sm2-inline-button {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  /* hide inner text */
  line-height: 10em;
  /**
   * image-rendering seems to apply mostly to Firefox in this case. Use with caution.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/image-rendering#Browser_compatibility
   */
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  -ms-interpolation-mode: bicubic; }

.sm2-icon-play-pause,
.sm2-icon-play-pause:hover,
.paused .sm2-icon-play-pause:hover {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/play.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/play.svg);
  background-size: 67.5%;
  background-position: 40% 53%; }

.playing .sm2-icon-play-pause {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/pause.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/pause.svg);
  background-size: 57.6%;
  background-position: 50% 53%; }

.sm2-volume-control {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/volume.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/volume.svg); }

.sm2-volume-control,
.sm2-volume-shade {
  background-position: 42% 50%;
  background-size: 56%; }

.volume-shade {
  filter: alpha(opacity=33);
  /* <= IE 8 */
  opacity: 0.33;
  /* -webkit-filter: invert(1);*/
  background-image: url(../images/player/icomoon/entypo-25px-000000/PNG/volume.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-000000/SVG/volume.svg); }

.sm2-icon-menu {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/list2.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/list2.svg);
  background-size: 58%;
  background-position: 54% 51%; }

.sm2-icon-previous {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/first.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/first.svg); }

.sm2-icon-next {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/last.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/last.svg); }

.sm2-icon-previous,
.sm2-icon-next {
  background-size: 49.5%;
  background-position: 50% 50%; }

.sm2-extra-controls .sm2-icon-previous,
.sm2-extra-controls .sm2-icon-next {
  backgound-size: 53%; }

.sm2-icon-shuffle {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/shuffle.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/shuffle.svg);
  background-size: 45%;
  background-position: 50% 50%; }

.sm2-icon-repeat {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/loop.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/loop.svg);
  background-position: 50% 43%;
  background-size: 54%; }

.sm2-extra-controls .sm2-icon-repeat {
  background-position: 50% 45%; }

.sm2-playlist-wrapper ul li .sm2-row {
  display: table;
  width: 100%; }

.sm2-playlist-wrapper ul li .sm2-col {
  display: table-cell;
  vertical-align: top;
  /* by default, collapse. */
  width: 0%; }

.sm2-playlist-wrapper ul li .sm2-col.sm2-wide {
  /* take 100% width. */
  width: 100%; }

.sm2-playlist-wrapper ul li .sm2-icon {
  display: inline-block;
  overflow: hidden;
  width: 2em;
  color: transparent !important;
  /* hide text */
  white-space: nowrap;
  /* don't let text affect height */
  padding-left: 0px;
  padding-right: 0px;
  text-indent: 2em;
  /* IE 8, mostly */ }

.sm2-playlist-wrapper ul li .sm2-icon,
.sm2-playlist-wrapper ul li:hover .sm2-icon,
.sm2-playlist-wrapper ul li.selected .sm2-icon {
  background-size: 55%;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.sm2-playlist-wrapper ul li .sm2-col {
  /* sibling table cells get borders. */
  border-right: 1px solid rgba(0, 0, 0, 0.075); }

.sm2-playlist-wrapper ul li.selected .sm2-col {
  border-color: rgba(255, 255, 255, 0.075); }

.sm2-playlist-wrapper ul li .sm2-col:last-of-type {
  border-right: none; }

.sm2-playlist-wrapper ul li .sm2-cart,
.sm2-playlist-wrapper ul li:hover .sm2-cart,
.sm2-playlist-wrapper ul li.selected .sm2-cart {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/cart.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/cart.svg);
  /* slight alignment tweak */
  background-position: 48% 50%; }

.sm2-playlist-wrapper ul li .sm2-music,
.sm2-playlist-wrapper ul li:hover .sm2-music,
.sm2-playlist-wrapper ul li.selected .sm2-music {
  background-image: url(../images/player/icomoon/entypo-25px-ffffff/PNG/music.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-ffffff/SVG/music.svg); }

.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li .sm2-cart,
.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li:hover .sm2-cart,
.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li.selected .sm2-cart {
  background-image: url(../images/player/icomoon/entypo-25px-000000/PNG/cart.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-000000/SVG/cart.svg); }

.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li .sm2-music,
.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li:hover .sm2-music,
.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li.selected .sm2-music {
  background-image: url(../images/player/icomoon/entypo-25px-000000/PNG/music.png);
  background-image: none, url(../images/player/icomoon/entypo-25px-000000/SVG/music.svg); }

.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li .sm2-col {
  border-left-color: rgba(0, 0, 0, 0.15); }

.sm2-playlist-wrapper ul li .sm2-icon:hover {
  background-color: rgba(0, 0, 0, 0.33); }

.sm2-bar-ui .sm2-playlist-wrapper ul li .sm2-icon:hover {
  background-color: rgba(0, 0, 0, 0.45); }

.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li.selected .sm2-icon:hover {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(0, 0, 0, 0.125); }

.sm2-progress-ball .icon-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: none, url(../images/player/icomoon/free-25px-000000/SVG/spinner.svg);
  background-size: 72%;
  background-position: 50%;
  background-repeat: no-repeat;
  display: none; }

.playing.buffering .sm2-progress-ball .icon-overlay {
  display: block;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.sm2-element ul {
  font-size: 95%;
  list-style-type: none; }

.sm2-element ul,
.sm2-element ul li {
  margin: 0px;
  padding: 0px; }

.bd.sm2-playlist-drawer {
  /* optional: absolute positioning */
  /* position: absolute; */
  z-index: 3;
  border-radius: 0px;
  width: 100%;
  height: 0px;
  border: none;
  background-image: none;
  display: block;
  overflow: hidden;
  transition: height 0.2s ease-in-out; }

.sm2-bar-ui.fixed .bd.sm2-playlist-drawer,
.sm2-bar-ui.bottom .bd.sm2-playlist-drawer {
  position: absolute; }

.sm2-bar-ui.fixed .sm2-playlist-wrapper,
.sm2-bar-ui.bottom .sm2-playlist-wrapper {
  padding-bottom: 0px; }

.sm2-bar-ui.fixed .bd.sm2-playlist-drawer,
.sm2-bar-ui.bottom .bd.sm2-playlist-drawer {
  /* show playlist on top */
  bottom: 2.8em; }

.sm2-bar-ui .bd.sm2-playlist-drawer {
  opacity: 0.5;
  /* redraw fix for Chrome, background color doesn't always draw when playlist drawer open. */
  transform: translateZ(0); }

/* experimental, may not perform well. */
/*
.sm2-bar-ui .bd.sm2-playlist-drawer a {
 -webkit-filter: blur(5px);
}
*/
.sm2-bar-ui.playlist-open .bd.sm2-playlist-drawer {
  height: auto;
  opacity: 1; }

.sm2-bar-ui.playlist-open .bd.sm2-playlist-drawer a {
  -webkit-filter: none;
  /* blur(0px) was still blurred on retina displays, as of 07/2014 */ }

.sm2-bar-ui.fixed.playlist-open .bd.sm2-playlist-drawer .sm2-playlist-wrapper,
.sm2-bar-ui.bottom.playlist-open .bd.sm2-playlist-drawer .sm2-playlist-wrapper {
  /* extra padding when open */
  padding-bottom: 0.5em;
  box-shadow: none; }

.sm2-bar-ui .bd.sm2-playlist-drawer {
  transition: all 0.2s ease-in-out;
  transition-property: transform, height, opacity, background-color, -webkit-filter; }

.sm2-bar-ui .bd.sm2-playlist-drawer a {
  transition: -webkit-filter 0.2s ease-in-out; }

.sm2-bar-ui .bd.sm2-playlist-drawer .sm2-inline-texture {
  /* negative offset for height of top bar, so background is seamless. */
  background-position: 0px -2.8em; }

.sm2-box-shadow {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.15); }

.sm2-playlist-wrapper {
  position: relative;
  padding: 0.5em 0.5em 0.5em 0.25em;
  background-image: none, none; }

.sm2-playlist-wrapper ul {
  max-height: 9.25em;
  overflow: auto; }

.sm2-playlist-wrapper ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.sm2-playlist-wrapper ul li:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.03); }

.sm2-playlist-wrapper ul li a {
  display: block;
  padding: 0.5em 0.25em 0.5em 0.75em;
  margin-right: 0px;
  font-size: 90%;
  vertical-align: middle; }

.sm2-playlist-wrapper ul li a.sm2-exclude {
  display: inline-block; }

.sm2-playlist-wrapper ul li a.sm2-exclude .label {
  font-size: 95%;
  line-height: 1em;
  margin-left: 0px;
  padding: 2px 4px; }

.sm2-playlist-wrapper ul li:hover a {
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url(../images/player/black-20.png);
  background-image: none, none; }

.sm2-bar-ui.dark-text .sm2-playlist-wrapper ul li:hover a {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(../images/player/black-10.png);
  background-image: none, none; }

.sm2-playlist-wrapper ul li.selected a {
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(../images/player/black-20.png);
  background-image: none, none; }

.sm2-bar-ui.dark-text ul li.selected a {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(../images/player/black-10.png);
  background-image: none, none; }

.sm2-bar-ui .disabled {
  filter: alpha(opacity=33);
  /* <= IE 8 */
  opacity: 0.33; }

.sm2-bar-ui .bd .sm2-button-element.disabled:hover {
  background-color: transparent; }

.sm2-bar-ui .active,
.sm2-bar-ui.playlist-open .sm2-menu:hover {
  /* depressed / "on" state */
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  background-image: none; }

.firefox-fix {
  /**
   * This exists because of a Firefox bug from 2000
   * re: nested relative / absolute elements inside table cells.
   * https://bugzilla.mozilla.org/show_bug.cgi?id=63895
   */
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%; }

/* some custom scrollbar trickery, where supported */
.sm2-playlist-wrapper ul::-webkit-scrollbar {
  width: 10px; }

.sm2-playlist-wrapper ul::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px; }

.sm2-playlist-wrapper ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fff; }

.sm2-extra-controls {
  font-size: 0px;
  text-align: center; }

.sm2-bar-ui .label {
  position: relative;
  display: inline-block;
  font-size: 0.7em;
  margin-left: 0.25em;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 0px 3px;
  box-sizing: padding-box; }

.sm2-bar-ui.dark-text .label {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../images/player/black-10.png);
  background-image: none, none; }

.sm2-bar-ui .sm2-playlist-drawer .label {
  font-size: 0.8em;
  padding: 0px 3px; }

/* --- full width stuff --- */
.sm2-bar-ui .sm2-inline-element {
  display: table-cell; }

.sm2-bar-ui .sm2-inline-element {
  /* collapse */
  width: 1%; }

.sm2-bar-ui .sm2-inline-status {
  /* full width */
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.sm2-bar-ui > .bd {
  width: 100%; }

.sm2-bar-ui .sm2-playlist-drawer {
  /* re-hide playlist */
  display: block;
  overflow: hidden; }

.sm2-bar-ui {
  font-size: 16px; }

.sm2-bar-ui .sm2-main-controls,
.sm2-bar-ui .sm2-playlist-drawer {
  background-color: #e17860; }

.sm2-bar-ui .sm2-inline-texture {
  background: transparent; }
