.page {
	max-width: 100%;
	overflow: hidden;
	text-rendering: optimizelegibility;
}
#page-title {
	margin-bottom: rem-calc(20);
}
h3 {
	line-height: rem-calc(36);
	font-weight: normal;
}
p {
	font-size: rem-calc(26);
	line-height: rem-calc(44);
	margin-bottom: rem-calc(40);
	font-weight: 400;
	-ms-word-break: break-word;
		word-break: break-word;
	word-break: break-word;
	-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
			hyphens: auto;
}
.copy-link {
	text-decoration: none;
	color: $red-link;
}
a {
	outline: none;
}
.red-link {
	color: $red-link;
}
@include breakpoint(small only) {
	p {
		font-size: rem-calc(22);
		line-height: rem-calc(38);
	}
}