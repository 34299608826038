$nav-text-color: $white;
$nav-height: 75px;
$nav-background: #a29e98;
$offcanvas-padding: 20px;

.nav-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $nav-background;
	height: $nav-height;
	margin-bottom: 75px;
	@include position-fixed-top(4);
	.nav-bar-logo {
		padding: 0;
	}
	li {
		background: $nav-background;
	}

	a {
		color: $nav-text-color;
		transition: color 0.2s ease-in;

		&:hover {
			color: lighten($nav-background, 50%);
		}
	}
	a.mobile-logo-wrap {
		max-width: 220px;
		display: inline-block;
	}
	.offcanvas-trigger {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.6rem;
		color: $nav-text-color;
		text-transform: uppercase;
		background-color: $nav-background;
		transition: background-color 0.2s ease-in;
		cursor: pointer;

		.offcanvas-trigger-text {
			margin-right: 0.25rem;
			margin-top: 0.25rem;
		}

		&:hover {
			background-color: $nav-background;
		}

	}
}
.multilevel-offcanvas {
	background: darken($nav-background, 5%);
	padding: 50px 20px;
	color: $white;

	> .menu {
		margin-bottom: 1rem;
	}
	.menu a {
		color: rgba($nav-text-color,.6);
		padding-top: 7px;
		padding-bottom: 7px;
		transition: color 0.2s ease-in;

		&:hover {
			color: $nav-text-color;
		}
	}
	#main-menu a,
	.drilldown a {
		background: darken($nav-background, 5%);
	}

	.fa {
		color: $nav-text-color;
	}
	 .close-button {
		outline: 0;
		z-index: 7;
		color:  $nav-text-color;
	}
	.is-accordion-submenu-parent > a::after {
		border-color: rgba($nav-text-color,.6) transparent transparent;
	}

	.social-links {
		margin-left: $offcanvas-padding;

		li {
			margin-right: 5px;
		}

		a:hover .fa {
			color: darken($nav-text-color,25%);
			transition: color .2s ease-in;
		}

		.fa {
			font-size: 2.2rem;
		}
	}
}

.body-info {
	text-align: center;
	color: $nav-background;

	.button {
		margin: 1rem 0;
		padding: 0.85em 2em;
	}
}

.is-open + .off-canvas-content .hamburger {
	.line:nth-child(1),
	.line:nth-child(3) {
		width: 12px;
	}

	.line:nth-child(1) {
		transform: translateX(-5px) rotate(-45deg);
	}

	.line:nth-child(3) {
		transform: translateX(-5px) rotate(45deg);
	}
}

.hamburger .line {
	width: 20px;
	height: 2px;
	background-color: $nav-text-color;
	display: block;
	margin: 3px auto;
	transition: all 0.3s ease-in-out;

	&:last-child {
		margin-bottom: 0;
	}
}

//	/**** Social Menu ***/
#social-nav {
	@include grid-column(6);
	max-width: 151px;
	float: right;
}
.social-bar-section .menu {
	@include button-group(".social-btn", 15);
	margin-right: 0;
	right: 0;
	li {
		display: inline-block;
		padding: 0 0 0 rem-calc(37);
	}
	li.first {
		padding-left: rem-calc(0);
	}
	li.last {
		padding-right: 0;
	}
	.social-btn {
		//@include button();
		background-color: $silver;
		outline: none;
		padding: 0;
		margin-bottom: 0;
		color: $silver;
		text-transform: uppercase;
		font-weight: normal;
		font-size: rem-calc(20);
		line-height: rem-calc(20);
		text-indent: 9999px;
		height: rem-calc(22);
		&:hover {
			background-color: $silver;
			color: $silver;
		}
	}
}
#fb-btn {
	background: url('../images/layout/images/menu-social-icon-bar.png') no-repeat 0 0 $smoke;
	width: rem-calc(19);
}
#twitter-btn {
	background: url('../images/layout/images/menu-social-icon-bar.png') no-repeat -113px 0 $smoke;
	width: rem-calc(23);
}
#instagram-btn {
	background: url('../images/layout/images/menu-social-icon-bar.png') no-repeat -57px 0 $smoke;
	padding-right: 0;
	width: rem-calc(20);
}
@include breakpoint(small only) {
	.nav-bar {
		margin-bottom: 0;
	}
}
