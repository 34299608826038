// Drupal comes with CSS quirks (Drupalisms). Unravel or override them here.

// Make the "sticky" Top Bar play nice with the "fixed" Admin Menu.
.admin-menu .fixed {
  top: emCalc(29px);
}
#admin-menu a,
#admin-menu .dropdown li li a  {
	font-size: rem-calc(12);
}
// Remove the bottom padding on status messages when displayed inside a Zurb
// Foundation Reveal modal.
#status-messages.reveal-modal {
  .alert-box {
    margin-bottom: 0;
  }
}

// Fix Zurb Foundation Reveal Modal z-index and make it play nice with dropdown
// menus, lightboxes, etc.
.reveal-modal {
  z-index: 999;
}
.tasks-block {
	.button,
	.button:hover {
		color: $white;
	}
}
